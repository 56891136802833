<template>
  <b-nav-item v-b-modal.log-systems>
    <feather-icon size="21" icon="TerminalIcon" :style="{ color: '#82868b !important' }"  class="text-secondary" />
    <b-modal id="log-systems" class="log-systems" size="lg" hide-footer @show="showModal" ref="ModalLogSystems">
      <template #modal-header>
        <div class="d-flex align-items-center">
          <feather-icon icon="TerminalIcon" size="20" style="margin-right: 14px" /> {{ $t('Logs') }}{{ `${logs.length > 0 ? '(' + logs.length + ')' : ''}` }}
          <span :title="$t('download')"><feather-icon icon="DownloadIcon" size="20" style="margin-left: 14px; cursor: pointer" @click="exportFileLogs" /></span>
        </div>
        <button type="button" @click="$bvModal.hide('log-systems')" aria-label="Close" :title="$t('close')" class="close">×</button>
      </template>
      <!-- BEGIN: Content-->
      <virtual-list style="max-height: 500px; overflow-y: auto" :data-key="'Level'" :keeps="30" :data-sources="logs" :data-component="itemComponent" />
      <!-- END: Content -->
    </b-modal>
  </b-nav-item>
</template>
<script>
import DownloadMixin from '@/mixins/DownloadMixin';
import { LOGS_PATH } from '@/constants/paths';
import VirtualListLog from './VirtualListLog';
import VirtualList from 'vue-virtual-scroll-list';
import { APP_NAME } from '@/constants/app';
import { ACTION_LOG } from '@/constants/actionLog';
const ICONNOTIFICATIONS = { info: 'CheckCircleIcon', error: 'XCircleIcon', warn: 'AlertTriangleIcon' };
export default {
  components: { VirtualList },
  mixins: [DownloadMixin],
  props: ['countClick'],
  data() {
    return {
      logs: [],
      iconNotifications: ICONNOTIFICATIONS,
      itemComponent: VirtualListLog,
    };
  },
  computed: {
    logPath() {
      return LOGS_PATH;
    },
    projectName() {
      return this.$store.state.ecoplot.project ? this.$store.state.ecoplot.project.name : APP_NAME;
    },
  },
  watch: {
    countClick() {
      this.$refs.ModalLogSystems.show(true);
    },
  },
  methods: {
    exportFileLogs() {
      let dataLogs = `Status,Time,Action,Duration,Project,Message \r\n`;
      let logsTemp = [...this.$logs];
      if (logsTemp.length > 0) {
        for (let i = 0; i < logsTemp.length; i++) {
          dataLogs += `${logsTemp[i].Level.toUpperCase()},${logsTemp[i].Time},${ACTION_LOG[logsTemp[i].Id].action},${logsTemp[i].Duration ? `${logsTemp[i].Duration}s` : ``},${logsTemp[i].Project},${logsTemp[i].Messages} \r\n`;
        }
      }
      this.asLogs(`${this.projectName}_log.csv`, dataLogs);
    },
    showModal() {
      this.logs = [...this.$logs].reverse();
    },
  },
};
</script>
<style lang="scss">
#log-systems.modal {
  // top: 80px !important;
  .modal-body {
    padding-right: 0px !important;
  }
  .modal-content {
    padding-bottom: 10px !important;
  }
  .modal-dialog.modal-lg {
    margin-top: 110px !important;
  }
}
</style>
