<template>
  <b-nav-item @click.stop="changeSkin" id="project-theme" :title="themeName">
    <!-- <feather-icon size="21" icon="SunIcon" /> -->
    <!-- <b-tooltip triggers="hover" target="project-theme" title="Theme" /> -->
    <component :is="icon" :style="{ width: '21px', height: '21px' }" />
  </b-nav-item>
</template>

<script>
import useAppConfig from '../../../../../@core/app-config/useAppConfig'
import { BNavItem, BTooltip } from 'bootstrap-vue'
const dark = require('/static/images/theme/dark.svg').default
const dawn = require('/static/images/theme/dawn.svg').default
const light = require('/static/images/theme/light.svg').default

export default {
  components: { BNavItem, BTooltip },
  setup() {
    const { skin } = useAppConfig()
    return { skin }
  },
  computed: {
    themeName() {
      const names = { 'dark': this.$i18n.t('semi-dark'), 'semi-dark': this.$i18n.t('light'), 'light': this.$i18n.t('dark') }
      return names[this.skin]
    },
    icon() {
      if (this.skin === 'dark') return dark
      else if (this.skin === 'semi-dark') return dawn
      else if (this.skin === 'light') return light
      return null
    },
  },
  methods: {
    changeSkin() {
      if (this.skin === 'dark') this.skin = 'semi-dark'
      else if (this.skin === 'semi-dark') this.skin = 'light'
      else if (this.skin === 'light') this.skin = 'dark'
    },
  },
}
</script>
