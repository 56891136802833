<template>
  <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">{{ user.full_name }}</p>
        <span class="user-status">{{ $t('member') }}</span>
      </div>
      <b-avatar size="40" :src="user.avatar" class="user-avatar" variant="light-primary">
        <!-- <feather-icon v-if="!userData.fullName" icon="UserIcon" size="22" /> -->
      </b-avatar>
    </template>

    <b-dropdown-item link-class="d-flex align-items-center" to="/profile">
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>{{ $t('profile') }}</span>
    </b-dropdown-item>

    <b-dropdown-divider />
    <b-dropdown-item link-class="d-flex align-items-center">
      <feather-icon size="16" icon="BookIcon" class="mr-50" />
      <span>{{ $t('document') }}</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center" to="/about">
      <feather-icon size="16" icon="InfoIcon" class="mr-50" />
      <span>{{ $t('about') }}</span>
    </b-dropdown-item>
    <!-- <b-dropdown-item :to="{ name: 'apps-email' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="MailIcon" class="mr-50" />
      <span>Inbox</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'apps-todo' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
      <span>Task</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'apps-chat' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
      <span>Chat</span>
    </b-dropdown-item> -->

    <!-- <b-dropdown-divider /> -->

    <!-- <b-dropdown-item :to="{ name: 'pages-account-setting' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span>Settings</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item link-class="d-flex align-items-center">
      <feather-icon size="16" icon="CreditCardIcon" class="mr-50" />
      <span>Pricing</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item :to="{ name: 'pages-faq' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
      <span>FAQ</span>
    </b-dropdown-item> -->

    <b-dropdown-divider />

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>{{ $t('logout') }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar } from 'bootstrap-vue'
// import { initialAbility } from '@/libs/acl/config'
// import { avatarText } from '../../../../../@core/utils/filter'

export default {
  components: { BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar },
  data() {
    return {
      // userData: JSON.parse(window.localStorage.getItem('userData')),
      // userData: {},
      // avatarText,
    }
  },
  computed: {
    user() {
      return this.$store.state.ecoplot.user
    },
  },
  methods: {
    logout() {
      this.$store.commit('ecoplot/CHANGE_PROJECT', { project: null })
      this.$store.commit('ecoplot/CHANGE_TEMP_PROJECT', { tempProject: { name: 'New Project', description: 'Project description' } })
      // this.$store.dispatch('datasource/CLEAR_DATASOURCE')
      this.clearDatasource()
      this.$store.commit('layer/CLEAR_LAYER')
      this.$store.dispatch('ecoplot/LOGOUT')
      this.$fromRoute = null
      // window.localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // window.localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // window.localStorage.removeItem('userData')

      // Reset ability
      // this.$ability.update(initialAbility)

      // Redirect to login page
      // this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>

<style>
.user-avatar span {
  background-color: #f6f6f6 !important;
}
.user-avatar img {
  width: 80% !important;
  height: 80% !important;
  box-shadow: none !important;
}
</style>
