<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link nav-link-bar" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <div class="align-items-center flex-grow-1 d-none d-lg-flex">
      <b-navbar-nav class="nav align-items-center">
        <project-menu />
        <project-settings />
        <map-base-time-series-loader :isActivate="true"  />
      </b-navbar-nav>
    </div>
    <div class="align-items-center flex-grow-1 d-flex d-lg-none">
      <b-navbar-nav class="nav align-items-center" :style="{marginLeft:`42px`}">
        <map-base-time-series-loader :isActivate="true" />
      </b-navbar-nav>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <dark-toggler class="d-none d-lg-block" />

      <Setting />
      <OtherApp />

      <div>
        <UserDropdown v-if="user" />
        <b-nav-item class="btn-login" v-else to="/login" title="Login">
          <b-button variant="gradient-success">{{ $t('login') }}</b-button>
        </b-nav-item>
      </div>
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BButton, BNavItem } from 'bootstrap-vue'
import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import ProjectMenu from '@/views/ecoplot-desktops/navbar/project-menu/ProjectMenu.vue'
import ProjectSettings from '@/views/ecoplot-desktops/navbar/project-tools/ProjectSettings.vue'
import Setting from '@/views/ecoplot-desktops/navbar/app-tools/Setting.vue'
import OtherApp from '@/views/ecoplot-desktops/navbar/app-tools/OtherApp.vue'
import mapBaseTimeSeriesLoader from '@/views/ecoplot-desktops/navbar/map-based-time-series-loader/mapBaseTimeSeriesLoader.vue';
export default {
  props: ['toggleVerticalMenuActive'],
  components: { BLink, BNavbarNav, BButton, BNavItem, Locale, DarkToggler, CartDropdown, UserDropdown, ProjectMenu, ProjectSettings, Setting, OtherApp, OtherApp,mapBaseTimeSeriesLoader },
  computed: {
    user() {
      return this.$store.state.ecoplot.user
    },
  },
  mounted() {
    // Get current login user
    this.$store.dispatch('ecoplot/GET_USER')
  },
}
</script>
