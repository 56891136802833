<template>
  <b-nav-item class="project-settings" :class="project === null || project.source === 'local' ? 'disabled not-allowed' : ''">
    <div class="project-settings-container">
      <div class="project-settings__editor">
        <input type="text" class="ip-project-name" :value="project ? project.name : tempProject.name" @input="updateName" @change="changeName" />
        <textarea rows="1" :placeholder="$t('project_description')" :value="project ? project.description : tempProject.description" @input="updateDescription" @change="changeDescription"></textarea>
      </div>
      <div class="project-settings__info">
        <button class="project-settings__button" ref="btnShowInfo">
          <div style="padding: 0 7px" :title="$t('info')">
            <feather-icon size="21" icon="InfoIcon" :style="{ color: '#82868b !important' }" />
          </div>
          <div class="additional-info">
            <div v-if="project">
              <div class="additional-info-item">
                <feather-icon size="16" icon="UserCheckIcon" style="margin-right: 5px" />
                <span>{{ $t('owned_by') }}</span>
                <b>{{ project.owner_name }}</b>
              </div>
              <div class="additional-info-item">
                <feather-icon size="16" icon="PackageIcon" style="margin-right: 5px" />
                <span>{{ $t('project_size') }}</span>
                <b>{{ project.size | bytes }}</b>
              </div>
              <div class="additional-info-item">
                <feather-icon size="16" icon="CalendarIcon" style="margin-right: 5px" />
                <span>{{ $t('first_created') }}</span>
                <b :title="project.created | date">{{ project.created | relative($i18n.locale) }}</b>
              </div>
              <div class="additional-info-item">
                <feather-icon size="16" icon="CalendarIcon" style="margin-right: 5px" />
                <span>{{ $t('last_modified') }}</span>
                <b :title="project.updated | date">{{ project.updated | relative($i18n.locale) }}</b>
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  </b-nav-item>
</template>

<script>
const dayjs = require('dayjs');
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
require('dayjs/locale/en');
require('dayjs/locale/ja');
import { ACTION_LOG } from '@/constants/actionLog';

export default {
  props: ['isActivate', 'countClick'],
  mounted() {
    this.oldName = this.project ? this.project.name : this.tempProject.name;
    this.oldDescription = this.project ? this.project.description : this.tempProject.description;
  },
  watch: {
    countClick() {
      this.$refs.btnShowInfo.focus();
    },
  },
  data() {
    return {
      oldName: '',
      oldDescription: '',
    };
  },
  filters: {
    date(value) {
      return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
    },
    relative(value, locale) {
      return dayjs(value).locale(locale).from(dayjs(), false);
    },
    bytes(value) {
      if (value === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(value) / Math.log(k));
      return parseFloat((value / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    },
  },
  computed: {
    project() {
      return this.$store.state.ecoplot.project;
    },
    tempProject() {
      return this.$store.state.ecoplot.tempProject;
    },
  },
  methods: {
    changeName(ev) {
      let newName = ev.target.value;
      if (this.oldName.trim().toLowerCase() != newName.trim().toLowerCase()) {
        let messageTitle = 'rename_project';
        let messageLog = `: '${this.oldName}' -> '${newName}'`;
        let message = this.$t(messageTitle) + messageLog;
        this.log.info({ message, id: ACTION_LOG[5].id, messageTitle, messageLog });
      }
      this.oldName = ev.target.value;
    },
    changeDescription(ev) {
      let newDecription = ev.target.value;
      let messageTitle = 'change_description';
      let messageLog = `: ${this.oldDescription} -> ${newDecription}`;
      let message = this.$t(messageTitle) + messageLog;
      this.log.info({ message, id: ACTION_LOG[6].id, messageTitle, messageLog });
      this.oldDescription = ev.target.value;
    },
    updateName(ev) {
      if (this.project) {
        this.$store.commit('ecoplot/UPDATE_PROJECT', { name: ev.target.value });
      } else {
        this.$store.commit('ecoplot/UPDATE_TEMP_PROJECT', { name: ev.target.value });
      }
    },
    updateDescription(ev) {
      if (this.project) {
        this.$store.commit('ecoplot/UPDATE_PROJECT', { description: ev.target.value });
      } else {
        this.$store.commit('ecoplot/UPDATE_TEMP_PROJECT', { description: ev.target.value });
      }
    },
  },
};
</script>

<style lang="scss">
.project-settings {
  position: relative;
}

.header-navbar[class*='bg-'] .navbar-nav .nav-item.project-settings > a {
  color: inherit !important;
}

.header-navbar[class*='bg-'] .navbar-nav .nav-item.project-settings > a .additional-info-item span {
  color: inherit !important;
}

.header-navbar[class*='bg-'] .navbar-nav .nav-item.project-settings > a .additional-info-item svg {
  color: inherit !important;
  cursor: default;
}

.project-settings.disabled .project-settings__info {
  cursor: not-allowed;
}

.project-settings.disabled .project-settings__info * {
  pointer-events: none;
}

.header-navbar[class*='bg-'] .navbar-nav .nav-item.disabled > a svg {
  color: rgba(255, 255, 255, 0.5) !important;
}

.project-settings-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-settings__editor input,
.project-settings__editor input:focus,
.project-settings__editor input:active,
.project-settings__editor textarea,
.project-settings__editor textarea:focus,
.project-settings__editor textarea:active {
  display: block;
  box-shadow: none;
  border-color: transparent;
  padding: 0;
  background: transparent;
  color: white;
  outline: none !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-settings__editor input {
  font-size: 14px;
  font-weight: bold;
  border-width: 1px;
}
.project-settings__editor textarea {
  font-size: 10px;
  width: 100%;
  overflow: hidden;
  resize: none;
}
.project-settings__editor input::placeholder,
.project-settings__editor textarea::placeholder {
  color: #f0f0f0;
  font-style: italic;
}
.project-settings__info {
  display: flex;
  align-items: center;
}
.project-settings__button {
  border: none;
  outline: none;
  background-color: transparent;
  text-align: left;
  color: inherit;
  padding: 0;
}
.project-settings__info svg {
  cursor: pointer;
}

.project-settings .additional-info {
  position: absolute;
  /* top: calc(100% + 0.8rem + 0.5rem);
  left: -10%; */
  top: -8px;
  right: calc(100% + 5px);
  width: 120%;
  min-width: 250px;
  background-color: rgba(246, 246, 246, 0.9);
  backdrop-filter: blur(0px);
  transition: all 0.25s, backdrop-filter 0s, max-height 0.3s;
  border-radius: 0.428rem;
  cursor: default;
  max-height: 0;
  overflow: hidden;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 24%);
}

.dark-layout .project-settings .additional-info {
  background-color: rgba(22, 29, 49, 0.9);
  backdrop-filter: blur(0px);
}

.project-settings .additional-info:hover {
  background-color: rgba(246, 246, 246, 1);
  backdrop-filter: blur(4px);
}

.dark-layout .project-settings .additional-info:hover {
  background-color: rgba(22, 29, 49, 1);
  backdrop-filter: blur(4px);
}

.project-settings__button:focus .additional-info {
  max-height: 100px;
}
.project-settings__button:focus-within .additional-info {
  max-height: 100px;
}

.project-settings .additional-info > div {
  padding: 7px;
  height: fit-content;
  line-height: 1;
}

.project-settings .additional-info .additional-info-item {
  margin-bottom: 0.5rem;
  font-size: 12px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.project-settings .additional-info .additional-info-item:last-child {
  margin-bottom: 0;
}
.project-settings .additional-info .additional-info-item b:last-child {
  margin-left: auto;
}
nav.floating-nav .project-settings-container .project-settings__info {
  display: none;
}
nav.floating-nav .project-settings-container .project-settings__editor {
  display: block;
}
.app-help-main .project-settings-container .project-settings__info {
  display: flex !important;
}
.app-help-main .project-settings-container .project-settings__editor {
  display: none !important;
}
.ip-project-name {
  width: 120px !important;
}
</style>
