<template>
  <b-nav-item v-b-modal.modal-settings :title="$t('setting')" @click.stop="setButtonClear">
    <feather-icon size="21" icon="SettingsIcon" />
    <b-modal id="modal-settings" cancel-title="Resest to default" centered size="lg" :title="$t('setting')" @show="showModalSettings" @hide="beforeHideModalSetting()">
      <template #modal-header>
        <div class="d-flex align-items-center"><feather-icon icon="SettingsIcon" style="margin-right: 10px" /> {{ $t('settings') }}</div>
        <button type="button" @click="$bvModal.hide('modal-settings')" :title="$t('close')" aria-label="Close" class="close">×</button>
      </template>
      <b-card-text>
        <section id="faq-tabs">
          <b-tabs vertical content-class="col-12 col-md-8 col-lg-9" pills nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12" nav-class="nav-left">
            <b-tab v-for="(categoryObj, index) in optionSetting" :key="categoryObj.title" :active="!index">
              <template #title>
                <feather-icon :icon="categoryObj.icon" size="18" class="mr-1" />
                <span class="font-weight-bold">{{ categoryObj.title }}</span>
              </template>
              <div v-if="categoryObj.value === 'general'">
                <b-row class="mb-1">
                  <b-col cols="6">
                    <b-form-group :label="$t('auto_format')">
                      <b-form-checkbox v-model="decimalAutoFormat" switch />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group :label="$t('decimal_scale')">
                      <b-form-input v-model="maxDecimal" :disabled="decimalAutoFormat" :placeholder="$t('decimal_scale')" type="number" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col cols="6">
                    <b-form-group :label="$t('live_mode')">
                      <div class="live-mode">
                        <label for="#ip-refresh-live-mode" class="live-mode-label">{{ $t('refresh') }}</label>
                        <div class="d-flex justify-content-center align-items-center"><b-form-input id="ip-refresh-live-mode" v-model="liveModeRefresh"></b-form-input><label class="label-text-ms">ms</label></div>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <div v-if="categoryObj.value === 'map'">
                <div style="margin-bottom: 1rem !important" class="d-flex align-items-center">
                  <b-avatar rounded size="42" variant="light-primary" class="mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar">
                      <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                      <line x1="16" y1="2" x2="16" y2="6"></line>
                      <line x1="8" y1="2" x2="8" y2="6"></line>
                      <line x1="3" y1="10" x2="21" y2="10"></line>
                    </svg>
                  </b-avatar>
                  <div>
                    <h2 class="mb-0">
                      {{ $t('calendar') }}
                    </h2>
                  </div>
                </div>

                <b-row>
                  <b-col cols="6">
                    <b-form-group :label="$t('show')">
                      <b-form-checkbox v-model="calendarShow" switch />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group :label="$t('unit')">
                      <vue-select label="title" :disabled="!calendarShow" :reduce="(data) => data.value" :options="calendarUnitChoices" v-model="calendarUnit" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <div style="margin-bottom: 1rem !important" class="d-flex align-items-center">
                  <b-avatar rounded size="42" variant="light-primary" class="mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map">
                      <polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon>
                      <line x1="8" y1="2" x2="8" y2="18"></line>
                      <line x1="16" y1="6" x2="16" y2="22"></line>
                    </svg>
                  </b-avatar>
                  <div>
                    <h2 class="mb-0">
                      {{ $t('map') }}
                    </h2>
                  </div>
                </div>
                <b-row>
                  <b-col cols="6">
                    <b-form-group :label="$t('show_mini_map')">
                      <b-form-checkbox v-model="minimap" switch />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group :label="$t('zoom_level')">
                      <b-form-input v-model="zoomSnap" type="number" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group :label="$t('select_color')">
                      <ColorPicker v-model="lineColor" :placeholder="$t('select_color')" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- <b-row class="mb-1">
                  <b-col cols="12">
                    <div style="margin-bottom: 1rem !important" class="d-flex align-items-center">
                      <b-avatar rounded size="42" variant="light-primary" class="mr-1">
                        <svg height="20px" viewBox="0 0 24 24" width="20px" xmlns="http://www.w3.org/2000/svg" class="feather feather-bar-chart-2" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                          <line x1="18" y1="20" x2="18" y2="10"></line>
                          <line x1="12" y1="20" x2="12" y2="4"></line>
                          <line x1="6" y1="20" x2="6" y2="14"></line>
                        </svg>
                      </b-avatar>
                      <div>
                        <h2 class="mb-0">
                          {{ $t('chart') }}
                        </h2>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group :label="$t('select_color')">
                      <ColorPicker v-model="lineColor" :placeholder="$t('select_color')" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group :label="$t('text_color')">
                      <ColorPicker v-model="chartTextColor" :placeholder="$t('text_color')" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group :label="$t('font_family')">
                      <vue-select :options="chartFontFamilyChoices" v-model="chartFontFamily" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group :label="$t('text_size')">
                      <b-form-input v-model="chartTextSize" :placeholder="$t('text_size')" type="number" />
                    </b-form-group>
                  </b-col>
                </b-row> -->
                <b-row class="mb-2">
                  <b-col cols="6">
                    <b-form-group :label="$t('group_legend')">
                      <b-form-checkbox v-model="groupLegend" switch />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <div class="custom-scrollbar box-component-filter" v-if="categoryObj.value === 'metadata'">
                <FormFilterMetadataComponent :dataFilterFlag="dataFilterFlag" @getDatafilter="getDataFilter" class="component-filter" />
              </div>
            </b-tab>
          </b-tabs>
        </section>
      </b-card-text>
      <template #modal-footer>
        <b-button variant="flat-secondary" @click="resetToDefault">{{ $t('reset_to_default') }}</b-button>
        <b-button variant="success" @click="saveSettings">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
  </b-nav-item>
</template>

<script>
const _ = require('lodash')
import '@/@core/scss/vue/pages/page-faq.scss'
import ColorPicker from '../../common/ColorPicker.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import FormFilterMetadataComponent from './FormFilterMetadataComponent.vue'
import { ThemeConfig } from '@/mixins/ThemeMixin'
import { ACTION_LOG } from '@/constants/actionLog'
export default {
  components: { ColorPicker, ToastificationContent, FormFilterMetadataComponent },
  mixins: [ThemeConfig],
  props: ['isActivate'],
  mounted() {
    if (!this.chartTextColor) {
      const color = this.theme === 'dark' ? '#d0d2d6' : '#444'
      this.chartTextColor = color
      this.$store.commit('settings/UPDATE_SETTINGS', { chartTextColor: color })
    }
  },
  data() {
    return {
      // Format
      defaultMaxDecimal: this.$store.state.settings.defaultMaxDecimal,
      maxDecimal: this.$store.state.settings.maxDecimal,
      maxDecimalNoSave: this.$store.state.settings.maxDecimal,
      decimalAutoFormat: this.$store.state.settings.decimalAutoFormat,
      // Chart
      lineColor: this.$store.state.settings.lineColor,
      chartFontFamily: this.$store.state.settings.chartFontFamily,
      chartFontFamilyChoices: ['Noto Sans JP', 'Calibri', 'Sans Serif', 'Serif', 'Cursive', 'Monospace'],
      chartTextColor: this.$store.state.settings.chartTextColor,
      chartTextSize: this.$store.state.settings.chartTextSize,
      chartAddYear: this.$store.state.settings.chartAddYear,
      // calendar
      calendarShow: this.$store.state.settings.calendarShow,
      minimap: this.$store.state.settings.minimap,
      calendarUnit: this.$store.state.settings.calendarUnit,
      zoomSnap: this.$store.state.settings.zoomSnap,
      dataFilter: this.$store.state.settings.dataFilter,
      //map
      groupLegend: this.$store.state.settings.groupLegend,
      liveModeRefresh: this.$store.state.settings.liveModeRefresh,
    }
  },
  computed: {
    dataFilterFlag() {
      return this.$store.state.settings.dataFilter
    },
    tempProject() {
      return this.$store.state.ecoplot.tempProject
    },
    optionSetting() {
      return [
        { title: this.$i18n.t('general'), icon: 'SettingsIcon', value: 'general' },
        { title: this.$i18n.t('map'), icon: 'MapIcon', value: 'map' },
        // { title: this.$i18n.t('chart'), icon: 'BarChart2Icon', value: 'chart' },
        { title: this.$i18n.t('metadata'), icon: 'TagIcon', value: 'metadata' },
      ]
    },
    axisRangeChoices() {
      return [
        { text: this.$i18n.t('normalize'), value: 'normalize' },
        { text: this.$i18n.t('same'), value: 'same' },
      ]
    },
    calendarUnitChoices() {
      return [
        { title: this.$i18n.t('auto'), value: 'auto' },
        { title: this.$i18n.t('hourly'), value: 'hourly' },
        { title: this.$i18n.t('daily'), value: 'daily' },
        { title: this.$i18n.t('monthly'), value: 'monthly' },
        { title: this.$i18n.t('yearly'), value: 'yearly' },
      ]
    },
    closeSetting() {
      return
    },
    locale() {
      return this.$i18n.locale
    },
  },
  watch: {
    theme() {
      const color = this.isDark ? '#d0d2d6' : '#444'
      this.chartTextColor = color
      this.$store.commit('settings/UPDATE_SETTINGS', { chartTextColor: color })
    },
    maxDecimal(value) {
      if (value < 0) {
        this.maxDecimal = this.defaultMaxDecimal
      }
    },
    decimalAutoFormat() {
      this.maxDecimalNoSave = this.maxDecimal
      this.commitModalSetting({ title: this.$t('settings_successfully_updated'), icon: 'RotateCcwIcon', variant: 'success', toast: false, hide: false })
    },
  },
  methods: {
    showModalSettings() {
      this.lineColor = this.$store.state.settings.lineColor
      this.chartFontFamily = this.$store.state.settings.chartFontFamily
      this.chartFontFamilyChoices = ['Noto Sans JP', 'Calibri', 'Sans Serif', 'Serif', 'Cursive', 'Monospace']
      this.chartTextColor = this.$store.state.settings.chartTextColor
      this.chartTextSize = this.$store.state.settings.chartTextSize
      this.chartAddYear = this.$store.state.settings.chartAddYear
    },
    setButtonClear() {
      setTimeout(() => {
        let buttonClear = document.querySelectorAll('.vs__clear')
        for (let i = 0; i < buttonClear.length; i++) {
          if (this.locale !== 'en') buttonClear[i].title = 'クリア'
          else buttonClear[i].title = 'Clear Selected'
        }
      }, 500)
    },
    getDataFilter(data) {
      this.dataFilter = _.cloneDeep(data)
    },
    beforeHideModalSetting() {
      this.maxDecimal = this.maxDecimalNoSave
    },
    resetToDefault() {
      this.maxDecimal = this.defaultMaxDecimal
      this.decimalAutoFormat = true
      this.lineColor = '#ff9439'
      // this.chartFontFamily = 'Noto Sans JP';
      // this.chartTextColor = '#d0d2d6';
      // this.chartTextSize = 14;
      this.calendarShow = true
      this.minimap = true
      this.calendarUnit = 'auto'
      this.chartAddYear = false
      this.zoomSnap = 1
      this.dataFilter = [{ id: 0, text: 'metadata', value: 'Metadata', icon: '', opened: false, selected: true, disabled: false, loading: false, fixed: true, children: [] }]
      this.groupLegend = false
      this.liveModeRefresh = 5000
      this.commitModalSetting({ title: this.$t('settings_reset_successfully'), icon: 'RotateCcwIcon', variant: 'success', hide: false })
    },
    saveSettings({ title = this.$t('settings_successfully_updated'), icon = 'SaveIcon', variant = 'success' } = {}) {
      this.commitModalSetting({ title, icon, variant })
    },
    commitModalSetting({ title = this.$t('settings_successfully_updated'), icon = 'SaveIcon', variant = 'success', toast = true, hide = true } = {}) {
      this.maxDecimalNoSave = this.maxDecimal == '' ? this.defaultMaxDecimal : this.maxDecimal
      this.$store.commit('settings/UPDATE_SETTINGS', {
        maxDecimal: Number(this.maxDecimal),
        decimalAutoFormat: this.decimalAutoFormat,
        lineColor: this.lineColor,
        // chartFontFamily: this.chartFontFamily,
        // chartTextColor: this.chartTextColor,
        // chartTextSize: Number(this.chartTextSize),
        calendarShow: this.calendarShow,
        minimap: this.minimap,
        calendarUnit: this.calendarUnit,
        chartAddYear: this.chartAddYear,
        zoomSnap: Number(this.zoomSnap),
        dataFilter: this.dataFilter,
        groupLegend: this.groupLegend,
        liveModeRefresh: this.liveModeRefresh,
      })
      if (toast == true) {
        this.$toast({ component: ToastificationContent, props: { title, icon, variant } })
      }
      if (hide == true) {
        this.$bvModal.hide('modal-settings')
      }
      this.log.info({ message: `${title}`, id: ACTION_LOG[1].id, messageTitle: 'settings_successfully_updated' })
    },
  },
}
</script>

<style>
#modal-settings .setting-title {
  display: flex;
  align-items: center;
}
#modal-settings .setting-title .feather {
  margin-right: 10px;
  width: 26px;
  height: 26px;
}
#modal-settings .custom-switch {
  margin-top: 7px;
}
input:disabled:hover {
  cursor: not-allowed !important;
}
.dark-layout .icon-menu-setting {
  margin-right: 10px;
  margin-top: 5px;
  fill: #d0d2d6;
}
.icon-menu-setting {
  margin-right: 10px;
  margin-top: 5px;
  fill: #5e5873;
}
</style>
<style scoped lang="scss">
.box-component-filter {
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
}
.live-mode {
  display: flex;
  align-items: center;
  font-size: 13px !important;
}
.live-mode-label {
  width: 115px;
  font-size: 13px !important;
}
#ip-refresh-live-mode {
  width: 70px;
  padding: 4px 10px !important;
  height: inherit;
  margin-right: 4px;
  border: 0.3px solid transparent;
  text-align: right;
  font-size: 13px !important;
  &:focus {
    border: 0.3px solid #d8d6de;
    text-align: center;
  }
  &:hover {
    border: 0.3px solid #d8d6de;
    text-align: center;
  }
}
.label-text-ms {
  font-size: 13px !important;
}
</style>
