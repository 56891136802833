<template>
  <b-modal :ok-title="$t('save')" ok-variant="success" :cancel-title="$t('cancel')" @hide="modalSaveHide" cancel-variant="flat-secondary" centered :title="$t('save_project_as')" ref="saveAsModal" size="lg" @ok="save">
    <div class="d-flex">
      <div style="flex-basis: 50%">
        <b-form-group>
          <label for="name">{{ $t('information') }}</label>
          <b-form-input id="name" type="text" placeholder="Project Name" v-model="name" />
        </b-form-group>
        <b-form-group>
          <b-form-input id="description" type="text" placeholder="Project Description" v-model="description" />
        </b-form-group>

        <b-form-group>
          <label for="destination">{{ $t('destination') }}</label>
          <b-form-radio-group class="d-block" id="destination" v-model="destination" button-variant="outline-primary" :options="destinations" buttons />
        </b-form-group>

        <b-form-group>
          <label for="extension">{{ $t('extension') }}</label>
          <b-form-radio-group class="d-block" id="extension" v-model="extension" button-variant="outline-primary" :options="extensions" buttons :disabled="destination === 'cloud'" />
        </b-form-group>
      </div>

      <div style="width: 50%; flex-basis: 50%; margin-left: 1rem; height: 351px">
        <div>
          <div class="d-flex" style="justify-content: space-between">
            <label>{{ $t('thumbnail') }}</label>
            <div class="icon-control-image upload-image" :title="$t('upload_files')" @click="uploadImage()">
              <feather-icon style="margin-bottom: 0.2857rem" icon="UploadIcon" size="16" class="cursor-pointer" />
            </div>
          </div>
          <img :src="thumbnailUri" alt="thumbnail" class="d-block mw-100" style="height: 250px; margin: auto" />
        </div>
        <div class="d-flex custom-scrollbar" style="overflow: auto">
          <div v-for="(img, index) in arrayImage" :key="index" style="margin: 5px 5px 0px 0px">
            <img :src="image2Base64(img)" alt="thumbnail" class="image-select-save rounded d-block w-100 h-100" @click="selectImage(img, index)" :class="index === countImage ? 'active-image-save' : null" />
          </div>
        </div>
      </div>
      <input type="file" ref="image" style="display: none" @change="addImage" accept=".png, .jpg, .gif" />
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      description: '',
      destinations: [
        { text: 'Local', value: 'local' },
        { text: 'Cloud', value: 'cloud' },
      ],
      destination: 'local',
      extensions: [
        { text: 'ECO', value: 'eco' },
        { text: 'ZIP', value: 'zip' },
      ],
      extension: 'eco',
      path: '',
      thumbnail: null,
      password: '',
      arrayImage: [],
      countImage: 0,
    }
  },
  computed: {
    user() {
      return this.$store.state.ecoplot.user
    },
    imageUpload() {
      return this.$store.state.ecoplot.imageUpload
    },
    thumbnailUri() {
      if (!this.thumbnail) return null
      return 'data:image/png;base64,' + this.thumbnail.toString('base64')
    },
  },
  // asyncComputed: {
  //   thumbnailUri: {
  //     default: null,
  //     async get() {
  //       if (!this.thumbnail) return null
  //       let buf = Buffer.from(await this.thumbnail.arrayBuffer())
  //       return 'data:image/png;base64,' + buf.toString('base64')
  //     },
  //   },
  // },
  methods: {
    modalSaveHide(){
      this.$emit('save-success')
    },
    image2Base64(img) {
      return 'data:image/png;base64,' + img.toString('base64')
    },
    selectImage(img, index) {
      this.countImage = index
      this.thumbnail = img
    },
    uploadImage() {
      this.$refs.image.click()
      // dialog
      //   .showOpenDialog(getCurrentWindow(), {
      //     properties: ['openFile'],
      //     filters: [{ name: 'Image File', extensions: ['png', 'jpg', 'gif'] }],
      //   })
      //   .then(({ canceled, filePaths }) => {
      //     if (canceled) return;
      //     try {
      //       if (this.arrayImage.length === 2) {
      //         this.arrayImage.length = 1;
      //         this.countImage = 0
      //         this.thumbnail = this.arrayImage[0];
      //       }
      //       const image = fs.readFileSync(filePaths[0])
      //       this.$store.commit('ecoplot/SET_PROJECT_THUMBNAILS', image);
      //       this.arrayImage.push(image);
      //     } catch (error) {
      //       console.log(error);
      //     }
      //   });
    },
    async open(name = 'New Project', description = 'Project Description', thumbnail = null) {
      this.arrayImage = []
      if (thumbnail) {
        let buf = Buffer.from(await thumbnail.arrayBuffer())
        this.arrayImage.push(buf)
        this.thumbnail = buf
      }
      if (this.imageUpload) this.arrayImage.push(this.imageUpload)
      this.name = name
      this.description = description
      this.path = ''
      this.password = ''
      this.$refs.saveAsModal.show()
    },
    save(event) {
      if (!this.user && this.destination === 'cloud') {
        this.$store.commit('ecoplot/SET_ERROR', { message: 'You must login before save a project to cloud' })
        event.preventDefault()
      } else {
        this.$emit('save', { name: this.name, description: this.description, destination: this.destination, extension: this.extension, path: this.path, thumbnail: this.thumbnail, password: this.password || undefined, imageUpload: this.imageUpload })
      }

    },
    addImage() {
      let file = this.$refs.image.files[0]
      let reader = new FileReader()
      reader.onload = () => {
        // let base64 = reader.result.toString()
        this.$refs.image.value = ''
        try {
          if (this.arrayImage.length === 2) {
            this.arrayImage.length = 1
            this.countImage = 0
            this.thumbnail = this.arrayImage[0]
          }
          var buffer = Buffer.from(new Uint8Array(reader.result))
          this.$store.commit('ecoplot/SET_PROJECT_THUMBNAILS', buffer)
          this.arrayImage.push(buffer)
        } catch (error) {
          console.log(error)
        }
      }
      reader.readAsArrayBuffer(file)
    },
    // getPath() {
    // let extension = this.extensions.find((e) => e.value === this.extension);
    // dialog
    //   .showSaveDialog(getCurrentWindow(), {
    //     defaultPath: this.name.replace(/[^\w\s]/gi, ''),
    //     filters: [{ name: `${extension.text} File`, extensions: [extension.value] }],
    //   })
    //   .then(({ canceled, filePath }) => {
    //     if (canceled) return;
    //     if (!filePath.endsWith(`.${extension.value}`)) filePath += `.${extension.value}`;
    //     this.path = filePath;
    //   });
    // },
  },
}
</script>
<style>
.image-select-save {
  cursor: pointer;
  width: 60px !important;
  height: 60px !important;
}
.active-image-save {
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
  border: 1px solid #397bff;
}
</style>
