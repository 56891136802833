<template>
  <b-nav-item-dropdown left toggle-class="d-flex align-items-center dropdown-project-link" style="padding-left: 0px" class="dropdown-project" @show="showDropdownmenu">
    <template #button-content>
      <div v-if="project && project.source === 'local'">
        <div v-if="!project.thumbnail" class="avatar-default"><b-avatar size="40" :src="logo" variant="light-primary" class="project-avatar" :title="$t('open_menu')"></b-avatar></div>
        <div v-if="project.thumbnail" class="avatar-select"><b-avatar size="40" :src="project.thumbnail" variant="light-primary" class="project-avatar" :title="$t('open_menu')"></b-avatar></div>
      </div>
      <div v-if="project && project.source === 'cloud'">
        <div class="avatar-select"><b-avatar size="40" :src="imageSource(project.id)" variant="light-primary" class="project-avatar" :title="$t('open_menu')"></b-avatar></div>
      </div>
      <div v-if="!project || (project && !project.source)">
        <div class="avatar-default"><b-avatar size="40" :src="logo" variant="light-primary" class="project-avatar" :title="$t('open_menu')"></b-avatar></div>
      </div>
    </template>

    <b-dropdown-item link-class="d-flex align-items-center" @click="newProject">
      <feather-icon size="16" icon="PlusIcon" class="mr-50" />
      <span>{{ $t('new_project') }}</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center" @click="openProject">
      <feather-icon size="16" icon="FolderIcon" class="mr-50" />
      <span>{{ $t('open_project') }}</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center" to="/projects" :disabled="user === null">
      <feather-icon size="16" icon="FolderIcon" class="mr-50" />
      <span>{{ $t('my_projects') }}</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item link-class="d-flex align-items-center" @click="saveProject(false, 'init')" :class="isProjectShare && (!permisionRealtime || permissionShare != 'write') ? 'disabled-style' : ''">
      <feather-icon size="16" icon="SaveIcon" class="mr-50" />
      <span>{{ $t('save') }}</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center" @click="saveAsProject(false, 'init')" :disabled="project === null" :class="isProjectShare && !permisionRealtime ? 'disabled-style' : ''">
      <feather-icon size="16" icon="SaveIcon" class="mr-50" />
      <span>{{ $t('save_as') }}</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item link-class="d-flex align-items-center" to="templates" :disabled="user === null">
      <feather-icon size="16" icon="ArchiveIcon" class="mr-50" />
      <span>{{ $t('prefix_templates') }}</span>
    </b-dropdown-item>

    <!-- <b-dropdown-divider /> -->

    <!-- <b-dropdown-item link-class="d-flex align-items-center" @click="quitApp">
      <feather-icon size="16" icon="XIcon" class="mr-50" />
      <span>{{ $t('exit_ecoplot') }}</span>
    </b-dropdown-item> -->

    <SaveModal ref="saveAsModal" @save="saveAs" @save-success="saveSuccessNoProject" />
    <input type="file" ref="datapack" style="display: none" @change="loadProject" accept=".zip,.eco" />
  </b-nav-item-dropdown>
</template>

<script>
import Vue from 'vue'
const axios = require('axios')
const _ = require('lodash')
import { ECOPLOT_API } from '@/constants/urls'
import CaptureMixin from '@/mixins/CaptureMixin.js'
import CompressMixin from '@/mixins/CompressMixin.js'
import { ThemeConfig } from '@/mixins/ThemeMixin.js';
import MathMixin from '@/mixins/MathMixin.js'
import SaveModal from './SaveModal.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import { APP_CONFIG ,TILE_LIGHT, TILE_DARK } from '@/constants/app.js';

export default {
  components: { SaveModal, ToastificationContent },
  mixins: [CaptureMixin, CompressMixin, MathMixin,ThemeConfig],
  data() {
    return {
      logo: require('/static/besec.png'),
      // permissionShare: 'read',
      flagSaveQuit: false,
      isRunSaveProject: null,
      methodSave: null, //or open
    }
  },
  computed: {
    user() {
      return this.$store.state.ecoplot.user
    },
    project() {
      return this.$store.state.ecoplot.project
    },
    tempProject() {
      return this.$store.state.ecoplot.tempProject
    },
    watchProjectUser() {
      return `${this.project}_${this.user}`
    },
    isProjectShare() {
      return this.$store.state.ecoplot.isProjectShare
    },
    permisionRealtime() {
      return this.$store.state.ecoplot.permisionRealtime
    },
    onWorkerSaveProjectContinue() {
      return this.$store.state.workerWindowEvent.workerSaveProjectContinue
    },
    onWorkerSaveAsProjectContinue() {
      return this.$store.state.workerWindowEvent.workerSaveAsProjectContinue
    },
    onWorkerNotSaveProjectContinue() {
      return this.$store.state.workerWindowEvent.workerNotSaveProjectContinue
    },
    permissionShare: {
      get() {
        return this.$store.state.ecoplot.permissionShare
      },
      set(permissionShare) {
        this.$store.commit('ecoplot/UPDATE_PERMISION_SHARE', permissionShare)
      },
    },
  },
  watch: {
    watchProjectUser() {
      try {
        if (this.user.username == this.project.owner || (this.project && this.project.mode == 'public' && this.project.permission == 'write')) {
          this.permissionShare = 'write'
          return
        }
        if (this.project && this.project.shared_persons && this.project.shared_persons.length) {
          for (let i = 0; i < this.project.shared_persons.length; i++) {
            if (this.project.shared_persons[i].person == this.user.username) {
              this.permissionShare = this.project.shared_persons[i].permission
              break
            }
          }
        }
      } catch {
        this.permissionShare = 'read'
      }
    },
    onWorkerSaveProjectContinue: {
      deep: true,
      handler(onWorkerSaveProjectContinue) {
        if (onWorkerSaveProjectContinue) {
          this.flagSaveQuit = false
          let sourceProject
          try {
            sourceProject = this.project.source
          } catch {}
          if (this.project === null || !sourceProject) {
            this.saveProject(false)
          } else {
            if (sourceProject == 'cloud') {
              this.save()
            } else {
              if (this.project.path) {
                this.save()
              } else {
                this.saveAsProject(false)
              }
            }
          }
        }
      },
    },
    onWorkerSaveAsProjectContinue: {
      deep: true,
      handler(onWorkerSaveAsProjectContinue) {
        if (onWorkerSaveAsProjectContinue) {
          this.flagSaveQuit = false
          if (this.project === null) {
            this.saveProject(false)
          } else {
            this.saveAsProject(false)
          }
        }
      },
    },
    onWorkerNotSaveProjectContinue: {
      deep: true,
      handler(onWorkerNotSaveProjectContinue) {
        if (onWorkerNotSaveProjectContinue) {
          this.isRunSaveProject = false
          this.notSaveContinue()
        }
      },
    },
    async isRunSaveProject() {
      if (this.isRunSaveProject) {
        if (this.methodSave == 'new') {
          this.$store.commit('ecoplot/SET_MODAL', { show: false, close: true })
          await this.funcNewProject()
        }
        if (this.methodSave == 'open') {
          this.$store.commit('ecoplot/SET_MODAL', { show: false, close: true })
          await this.openProjectDialog()
        }
      }
      this.$store.commit('workerWindowEvent/RESET_WORKER_EVENT')
    },
  },
  methods: {
    saveSuccessNoProject() {
      this.$store.commit('workerWindowEvent/RESET_WORKER_EVENT')
    },
    async notSaveContinue() {
      this.$store.commit('ecoplot/SET_MODAL', { show: false, close: true })
      if (this.methodSave == 'new') {
        await this.funcNewProject()
      }
      if (this.methodSave == 'open') {
        await this.openProjectDialog()
      }
      this.$store.commit('workerWindowEvent/RESET_WORKER_EVENT')
    },
    async delay(ms) {
      return new Promise((resolve) => setTimeout(() => resolve(), ms))
    },
    imageSource(id) {
      return `${ECOPLOT_API}/static/${id}.png`
    },
    async checkSaveProject() {
      this.$store.commit('workerWindowEvent/WORKER_SHOW_DIALOG_SAVE_PROJECT', true)
    },
    async funcNewProject() {
      try {
        Vue.prototype.$latlngOnMap = {}
        Vue.prototype.$imagesSrc = {}
        Vue.prototype.$netcdfStatus = {}
        Vue.prototype.$layerStatus = {}
        Vue.prototype.$mapBasedTimeseriesLoader = {}
        this.$store.commit('ecoplot/CHANGE_PROJECT', { project: null })
        this.$store.commit('ecoplot/CHANGE_TEMP_PROJECT', { tempProject: { name: 'New Project', description: 'Project Description' } })
        this.$store.commit('ecoplot/SET_MODAL', { close: true })
        this.$store.commit('ecoplot/RESET_STATE')
        this.$store.commit('groupItems/RESET_STATE')
        this.$store.commit('layer/RESET_STATE')
        this.$store.commit('map/RESET_STATE')
        if (this.isDark) {
          this.$store.commit('map/SET_MAP_TILES', [TILE_DARK,TILE_DARK]);
        } else {
          this.$store.commit('map/SET_MAP_TILES', [TILE_LIGHT,TILE_LIGHT]);
        }
        this.$store.commit('settings/RESET_STATE')
        this.$store.commit('mapBasedTimeseriesLoader/RESET_MAP_BASED_TIMESERIES_LOADER')
        await this.clearDatasource()
        this.$store.commit('tabs/RESET_STATE')
        this.$store.commit('ecoplot/INIT_PROJECT')
        this.$store.commit('ecoplot/SET_PROJECT_THUMBNAILS', null)
      } catch {}
      this.methodSave = null
      this.isRunSaveProject = true
    },
    async newProject() {
      this.methodSave = 'new'
      this.isRunSaveProject = false
      await this.checkSaveProject()
    },
    async openProject() {
      this.methodSave = 'open'
      this.isRunSaveProject = false
      await this.checkSaveProject()
    },
    openProjectDialog() {
      this.$refs.datapack.click()
    },
    async loadProject() {
      try {
        let route = this.$router.currentRoute
        if (route.name !== 'map') {
          await this.$router.push('map')
        }
      } catch {}
      let file = this.$refs.datapack.files[0]
      let password = undefined
      await this.extractProject(file, file.name, password, false)
      this.$refs.datapack.value = ''
    },

    async saveProject(flagSaveQuit, init = 'no-init') {
      if (init && init == 'init') {
        this.methodSave = null
      }
      try {
        this.flagSaveQuit = flagSaveQuit
        this.$store.commit('layer/GET_IMAGE_POSITION')
        if (this.project && !this.project.templates) {
          this.save()
        } else {
          let name = this.tempProject.name
          let description = this.tempProject.description
          let thumbnail = await this.capture()
          this.$refs.saveAsModal.open(name, description, thumbnail)
        }
      } catch {}
    },

    async saveAsProject(flagSaveQuit, init = 'no-init') {
      if (init && init == 'init') {
        this.methodSave = null
      }
      try {
        this.flagSaveQuit = flagSaveQuit
        this.$store.commit('layer/GET_IMAGE_POSITION')
        let name
        try {
          name = this.project.name.replace(/[^\w\s]/gi, '') + ' - Copy'
        } catch {
          name = this.project.name + ' - Copy'
        }
        let description = this.project.description
        let thumbnail = await this.capture()
        this.$refs.saveAsModal.open(name, description, thumbnail)
      } catch {}
    },
    save() {
      // save destination handler
      if (this.project.source === 'local') {
        this.saveLocal()
      } else if (this.project.source === 'cloud') {
        this.saveCloud()
      }
    },
    async saveLocal() {
      try {
        let { name, description, path, thumbnail } = this.project
        const projectInfor = _.merge({ name, description, thumbnail }, APP_CONFIG)
        let bufferData = await this.compressProject(projectInfor)
        let blob = new Blob([bufferData])
        let file = new File([blob], path)
        let downloadLink = document.createElement('a')
        let url = URL.createObjectURL(file)
        downloadLink.href = url
        downloadLink.download = file.name
        document.body.appendChild(downloadLink)
        downloadLink.click()
        window.URL.revokeObjectURL(url)
        this.$store.commit('ecoplot/CHANGE_PROJECT', { project: { name, description, source: 'local', path, thumbnail } })
        this.$toast({ component: ToastificationContent, props: { title: 'Saved successfully', icon: 'BellIcon' } })
      } catch {}
      this.isRunSaveProject = true
    },

    async saveCloud() {
      try {
        let token = localStorage.getItem('django-authentication-token')
        if (!token) return

        const formdata = new FormData()

        formdata.append('name', this.project.name)
        formdata.append('description', this.project.description)

        const projectInfor = _.merge({ name: this.project.name, description: this.project.description }, APP_CONFIG)
        let bufferData = await this.compressProject(projectInfor)
        formdata.append('zipfile', new Blob([bufferData], { type: 'application/zip' }))

        const response = await axios.put(`${ECOPLOT_API}/projects/${this.project.id}/`, formdata, { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Token ${token}` } })
        this.$store.commit('ecoplot/CHANGE_PROJECT', { project: { ...response.data, source: 'cloud', path: null } })
        this.$toast({ component: ToastificationContent, props: { title: this.$t('saved_successfully'), icon: 'BellIcon', variant: 'success' } })
      } catch {}
      this.isRunSaveProject = true
    },

    saveAs({ name, description, destination, extension, path, thumbnail, password, imageUpload }) {
      // save as destination handler
      if (destination === 'local') {
        this.saveAsLocal({ name, description, extension, path, thumbnail, password, imageUpload })
      } else if (destination === 'cloud') {
        this.saveAsCloud({ name, description, extension, path, thumbnail, password, imageUpload })
      }
    },

    async saveAsLocal({ name, description, extension, path, thumbnail, password, imageUpload }) {
      try {
        thumbnail = 'data:image/png;base64,' + thumbnail.toString('base64')
        const projectInfor = _.merge({ name, description, password: undefined, imageUpload, thumbnail }, APP_CONFIG)

        // Tạo một ArrayBuffer hoặc Buffer (dữ liệu tệp)
        let bufferData = await this.compressProject(projectInfor)

        let blob = new Blob([bufferData])
        path = `${name.replace(/[^\w\s]/gi, '')}.${extension}`
        let file = new File([blob], path)

        let downloadLink = document.createElement('a')
        let url = URL.createObjectURL(file)
        downloadLink.href = url
        downloadLink.download = file.name

        document.body.appendChild(downloadLink)
        downloadLink.click()
        window.URL.revokeObjectURL(url)

        this.$store.commit('ecoplot/CHANGE_PROJECT', { project: { name, description, source: 'local', path, thumbnail } })
        this.$toast({ component: ToastificationContent, props: { title: this.$t('saved_successfully'), icon: 'BellIcon', variant: 'success' } })
      } catch {}
      this.isRunSaveProject = true
    },
    async saveAsCloud({ name, description, extension, path, thumbnail, password, imageUpload }) {
      try {
        let token = localStorage.getItem('django-authentication-token')
        if (!token) return
        const formdata = new FormData()
        formdata.append('name', name)
        formdata.append('description', description)
        formdata.append('avatar', new Blob([thumbnail], { type: 'image/png' }))
        const projectInfor = _.merge({ name, description, imageUpload }, APP_CONFIG)
        let zip = await this.compressProject(projectInfor)
        formdata.append('zipfile', new Blob([zip], { type: 'application/zip' }))
        formdata.append('avatar', new Blob([thumbnail], { type: 'image/png' }))
        try {
          const response = await axios.post(`${ECOPLOT_API}/projects/`, formdata, { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Token ${token}` } })
          this.$store.commit('ecoplot/CHANGE_PROJECT', { project: { ...response.data, source: 'cloud', path: null } })
          this.$toast({ component: ToastificationContent, props: { title: this.$t('saved_successfully'), icon: 'BellIcon', variant: 'success' } })
        } catch (error) {
          this.$toast({ component: ToastificationContent, props: { title: 'Saved failed', icon: 'BellIcon', variant: 'danger' } })
          console.log(error)
        }
      } catch {}
      this.isRunSaveProject = true
    },
    quitApp() {
      // app.exit()
    },
    async showDropdownmenu() {
      try {
        let token = localStorage.getItem('django-authentication-token')
        if (!this.isProjectShare || !this.project || !this.project.id || !token || this.user.username == this.project.owner) {
          this.$store.commit('ecoplot/UPDATE_PERMISION_REALTIME', true)
          return
        }
        try {
          let response = await axios.get(`${ECOPLOT_API}/projects/${this.project.id}/`, { headers: { Authorization: `Token ${token}` } })
          this.$store.commit('ecoplot/UPDATE_PERMISION_REALTIME', true)
          this.$store.commit('ecoplot/CHANGE_PROJECT', { project: { ...response.data, source: 'cloud', path: null } })
          if (response && response.data && response.data.permission) {
            if (response.data.mode == 'public') {
              if (response.data.permission == 'write') {
                this.permissionShare = 'write'
                return
              }
              this.permissionShare = response.data.permission
            } else {
              this.permissionShare = 'read'
            }

            if (response.data.shared_persons && response.data.shared_persons.length) {
              for (let i = 0; i < response.data.shared_persons.length; i++) {
                if (response.data.shared_persons[i].person == this.user.username) {
                  this.permissionShare = response.data.shared_persons[i].permission
                  return
                }
              }
            }
          }
        } catch (err) {
          if (this.isProjectShare) {
            this.$store.commit('ecoplot/UPDATE_PERMISION_REALTIME', false)
          }
        }
      } catch {}
    },
  },
}
</script>

<style lang="scss">
.avatar-select .b-avatar-img {
  border-radius: 5px;
}
.dropdown-project .dropdown-menu {
  margin-top: 10px;
  left: -14px;
}
.dropdown-project .avatar-select img {
  filter: none;
  object-fit: cover;
}
.dropdown-project .avatar-default img {
  object-fit: contain !important;
  filter: brightness(0) invert(1);
}

.dropdown-project a.dropdown-project-link {
  padding-left: 0px !important;
  display: flex;
  align-items: center;

  .project-name {
    display: inline-block;
    margin-bottom: 0.435rem;
    margin-right: 0.2rem;
  }

  img {
    box-shadow: 0 4px 8px 0 rgba(#22292f, 0.12), 0 2px 4px 0 rgba(#22292f, 0.08); // use $black
  }

  .project-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    float: right;
    margin-left: 1rem - 0.2; // use $spacer
  }
}
.disabled-style {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

@media (max-width: 1199px) {
  .header-navbar {
    .navbar-container {
      ul.navbar-nav li a.dropdown-project-link .project-name {
        margin-bottom: 0;
      }
    }
  }
}
</style>
