<template>
  <div class="log-systems-content">
    <div :class="`mess-${source.Level != null ? source.Level : 'info'}`" class="log-systems-content-items">
      <div class="log-systems-content-items_icons"><feather-icon size="14" :icon="iconNotifications[source.Level != null ? source.Level : 'info']" /></div>
      <div class="log-systems-content-items_notifications">{{ `${source.Messages}` }}</div>
      <span v-if="source.LayerId" class="d-flex ml-5px" :title="objectSelected(source.LayerId)"><feather-icon icon="InfoIcon" size="14" /></span>
      <span v-if="source.Duration" class="ml-5px badge" :class="`badge-light-${source.Level == 'info' ? 'success' : 'warning'}`">{{ source.Duration }}s</span>
      <div class="log-systems-content-items_times">{{ source.Time }}</div>
    </div>
  </div>
</template>

<script>
import DownloadMixin from '@/mixins/DownloadMixin';
const ICONNOTIFICATIONS = { info: 'CheckCircleIcon', error: 'XCircleIcon', warn: 'AlertTriangleIcon' };
export default {
  mixins: [DownloadMixin],
  name: 'virtual-list-log',
  props: {
    index: {
      // index of current item
      type: Number,
    },
    source: {
      type: Object,
      default: null,
    },
  },
  computed: {
    layers() {
      return this.$store.state.layer.layers;
    },
  },
  data() {
    return {
      iconNotifications: ICONNOTIFICATIONS,
    };
  },
  methods: {
    objectSelected(layerId) {
      if (layerId && this.layers) {
        for (let index in this.layers) {
          if (this.layers[index].id == layerId) {
            return JSON.stringify(this.layers[index]);
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
.dark-layout {
  .log-systems-content {
    color: #82868b;
  }
}
#log-systems {
  .log-systems-content {
    &-items {
      display: flex;
      padding: 4px 0;
      font-size: 12px;
      align-items: center;
      &_icons {
        width: 2.5rem;
        display: flex;
        align-items: center;
      }
      &_notifications {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
      &_times {
        width: 9rem;
        text-align: right;
      }
    }
    .mess {
      &-info {
        svg {
          color: #28c76f;
        }
      }
      &-error {
        .log-systems-content-items_notifications {
          color: #ea5455;
        }
        svg {
          color: #ea5455;
        }
      }
      &-warn {
        .log-systems-content-items_notifications {
          color: #ff9f43;
        }
        svg {
          color: #ff9f43;
        }
      }
    }
  }
  div[role='listitem'] {
    margin-right: 1.35rem;
    &:not(:last-child) {
      border-bottom: solid 1px #cccccc24;
    }
  }
  .modal-body > div {
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #888888;
    }
  }
}
.ml-5px {
  margin-left: 5px;
}
</style>
