<template>
  <b-nav-item :title="$t('share')" @click="openModal" class="project-share" :class="project === null || project.source === 'local' ? 'disabled not-allowed' : ''">
    <feather-icon size="21" icon="Share2Icon" :style="{ color: '#82868b !important' }" />
    <!-- <b-tooltip triggers="hover" target="project-share" title="Share" /> -->

    <b-modal centered :title="$t('share_project')" ref="shareModal" :hide-footer="true">
      <b-form>
        <div :class="project && user && user.username != '' && user.username != project.owner ? 'disabled-style' : ''">
          <b-form-group :disabled="project && user && user.username != '' && user.username != project.owner ? true : false">
            <b-form-checkbox v-model="projectMode" switch inline>{{ $t('share_link') }}</b-form-checkbox>
          </b-form-group>
          <b-row>
            <b-col>
              <b-form-group>
                <b-form-input :value="`${url}/#/map?shareId=${project ? project.link : ''}`" readonly :disabled="!projectMode" id="share-link" />
              </b-form-group>
            </b-col>
            <b-col cols="auto">
              <b-button variant="primary" @click="doCopy" :disabled="!projectMode">{{ $t('copy') }}</b-button>
            </b-col>
          </b-row>
          <b-form-group>
            <b-form-radio-group v-model="projectPermission" button-variant="outline-primary" :options="permissions" buttons :disabled="!projectMode" />
          </b-form-group>
        </div>

        <hr />
        <b-form-group :label="$t('share_with')" :class="project && user && user.username != '' && user.username != project.owner ? 'disabled-style' : ''">
          <vue-select v-model="selectedPerson" :reduce="(data) => data.username" :options="persons" :placeholder="$t('email')" :filterable="false" @search="getPersons">
            <template v-slot:option="option">
              <div class="person-option">
                <b-img :src="option.avatar" rounded="circle" class="person-avatar"></b-img>
                <div class="person-info">
                  <p>{{ option.full_name }}</p>
                  <small>{{ option.username }}</small>
                </div>
              </div>
            </template>
          </vue-select>
        </b-form-group>
        <div v-if="project">
          <div class="shared-option" v-for="option in project.shared_persons" :key="option.id" :class="user && project && user.username != project.owner && user.username != option.person ? 'disabled-style' : ''">
            <b-img :src="option.avatar" rounded="circle" class="shared-avatar"></b-img>
            <div class="shared-info">
              <p>{{ option.name }}</p>
              <small>{{ option.person }}</small>
            </div>
            <b-form-radio-group :class="user && project && user.username != project.owner && user.username == option.person ? 'disabled-style' : ''" size="sm" button-variant="outline-primary" :options="permissions" :checked="option.permission" @input="updatePerson(option.id, $event)" buttons style="margin-left: 10px" />
            <feather-icon size="20" icon="TrashIcon" style="cursor: pointer; margin-left: 10px" @click="deletePerson(option.id)" />
          </div>
        </div>
      </b-form>
    </b-modal>
  </b-nav-item>
</template>

<script>
import { BNavItem, BTooltip, BModal, BForm, BFormGroup, BFormCheckbox, BRow, BCol, BFormInput, BButton, BFormRadioGroup, BImg } from 'bootstrap-vue'
import { ECOPLOT_API, ECOPLOT_PRO } from '@/constants/urls'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import VueSelect from 'vue-select'
import debounce from 'lodash/debounce'
const axios = require('axios')

export default {
  props: ['countClick'],
  components: { BNavItem, BTooltip, BModal, BForm, BFormGroup, BFormCheckbox, ToastificationContent, BRow, BCol, BFormInput, BButton, BFormRadioGroup, VueSelect, BImg },
  data() {
    return {
      url: ECOPLOT_PRO,
      persons: [],
      selectedPerson: null,
    }
  },
  computed: {
    permissions() {
      return [
        { text: this.$t('read'), value: 'read' },
        { text: this.$t('write'), value: 'write' },
      ]
    },
    project() {
      return this.$store.state.ecoplot.project
    },
    user() {
      return this.$store.state.ecoplot.user
    },
    projectMode: {
      get() {
        return this.project && this.project.mode === 'public'
      },
      set(value) {
        this.saveMode(value ? 'public' : 'private')
      },
    },
    message() {
      return `${this.url}/#/map?shareId=${this.project ? this.project.link : ''}`
    },
    projectPermission: {
      get() {
        return this.project ? this.project.permission : 'read'
      },
      set(value) {
        this.savePermission(value)
      },
    },
    isProjectShare() {
      return this.$store.state.ecoplot.isProjectShare
    },
    permisionRealtime() {
      return this.$store.state.ecoplot.permisionRealtime
    },
  },
  watch: {
    async countClick() {
      this.$refs.shareModal.show()
      try {
        let token = localStorage.getItem('django-authentication-token')
        if (!this.project || !this.project.id || !token) return
        try {
          let response = await axios.get(`${ECOPLOT_API}/projects/${this.project.id}/`, { headers: { Authorization: `Token ${token}` } })
          this.$store.commit('ecoplot/CHANGE_PROJECT', { project: { ...response.data, source: 'cloud', path: null } })
        } catch {
          this.$store.commit('ecoplot/UPDATE_PERMISION_REALTIME', false)
        }
      } catch {}
    },
    selectedPerson(person) {
      if (!person) return
      this.addPerson(person)
      this.selectedPerson = null
    },
  },
  methods: {
    openModal() {
      this.$refs.shareModal.show()
    },
    async saveMode(mode) {
      let token = localStorage.getItem('django-authentication-token')

      if (!this.user || !this.project || !token || this.user.username != this.project.owner) return

      let formdata = new FormData()
      formdata.append('mode', mode)

      let response = await axios.put(`${ECOPLOT_API}/projects/${this.project.id}/`, formdata, { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Token ${token}` } })
      this.$store.commit('ecoplot/CHANGE_PROJECT', { project: { ...response.data, source: 'cloud', path: null } })
    },
    doCopy() {
      let copyText = document.getElementById('share-link')
      copyText.select()
      navigator.clipboard.writeText(copyText.value)

      this.$toast({ component: ToastificationContent, props: { title: 'Text copied', icon: 'BellIcon' } })
    },
    async savePermission(permission) {
      let token = localStorage.getItem('django-authentication-token')

      if (!this.user || !this.project || !token || this.user.username != this.project.owner) return

      let formdata = new FormData()
      formdata.append('permission', permission)

      let response = await axios.put(`${ECOPLOT_API}/projects/${this.project.id}/`, formdata, { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Token ${token}` } })
      this.$store.commit('ecoplot/CHANGE_PROJECT', { project: { ...response.data, source: 'cloud', path: null } })
    },
    getPersons: debounce(async function (keyword) {
      this.persons = []
      if (!keyword.length) return

      let token = localStorage.getItem('django-authentication-token')

      if (!this.user || !this.project || !token || this.user.username != this.project.owner) return

      let response = await axios.get(`${ECOPLOT_API}/projects/${this.project.id}/shared-persons/?keyword=${keyword}`, { headers: { Authorization: `Token ${token}` } })
      this.persons = response.data
    }, 500),
    async addPerson(person) {
      let token = localStorage.getItem('django-authentication-token')

      if (!this.user || !this.project || !token || this.user.username != this.project.owner) return

      let response = await axios.post(`${ECOPLOT_API}/projects/${this.project.id}/shared-persons/`, { email: person }, { headers: { Authorization: `Token ${token}` } })
      this.$store.commit('ecoplot/ADD_SHARED_PERSON', response.data)
    },
    async updatePerson(id, permission) {
      let token = localStorage.getItem('django-authentication-token')

      if (!this.user || !this.project || !token || this.user.username != this.project.owner) return

      let response = await axios.put(`${ECOPLOT_API}/projects/${this.project.id}/shared-persons/${id}/`, { permission }, { headers: { Authorization: `Token ${token}` } })
      this.$store.commit('ecoplot/UPDATE_SHARED_PERSON', response.data)
    },
    async deletePerson(id) {
      let token = localStorage.getItem('django-authentication-token')

      if (!token) return

      await axios.delete(`${ECOPLOT_API}/projects/${this.project.id}/shared-persons/${id}/`, { headers: { Authorization: `Token ${token}` } })
      this.$store.commit('ecoplot/DELETE_SHARED_PERSON', { id })
    },
  },
}
</script>

<style scoped>
.project-share.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.project-share.disabled * {
  cursor: not-allowed;
  pointer-events: none;
}

.person-option {
  display: flex;
  align-items: center;
}
.person-avatar {
  width: 36px;
  height: 36px;
  object-fit: cover;
  margin-right: 10px;
}
.person-info {
  flex-grow: 1;
}
.person-info p {
  margin-bottom: 0;
}
.person-info small {
  display: block;
}

.shared-option {
  display: flex;
  align-items: center;
  padding: 10px;
}
.shared-avatar {
  width: 36px;
  height: 36px;
  object-fit: cover;
  margin-right: 10px;
}
.shared-info {
  flex-grow: 1;
}
.shared-info p {
  margin-bottom: 0;
}
.shared-info small {
  display: block;
}
.disabled-style {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
<style lang="scss">
.disabled-style {
  .custom-control-label {
    cursor: default;
    &::after {
      cursor: default;
    }
    &::before {
      cursor: default;
    }
  }
}
</style>
