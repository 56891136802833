<template>
  <div class="main-menu menu-fixed menu-accordion menu-shadow" :class="[{ expanded: !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) }, skin === 'semi-dark' ? 'menu-dark' : 'menu-light']" @mouseenter="updateMouseHovered(true)" @mouseleave="updateMouseHovered(false)">
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed" :collapseTogglerIcon="collapseTogglerIcon">
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <div class="navbar-brand">
              <span @click="openLink('https://ecoplot.besec.co.jp/')" class="brand-logo" :title="$t('logo_title')">
                <b-img :src="appLogoImage" alt="logo" />
              </span>
              <h2 class="brand-text">
                <span class="app-name">{{ appName }}</span>
                <i class="app-version text-secondary">{{ $t('version', { version: appVersion }) }}</i>
              </h2>
            </div>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle" :title="collapseTogglerIconFeather === 'ChevronsLeftIcon' ? $t('collapse') : $t('expand')">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon icon="XIcon" size="20" class="d-block d-xl-none" @click="toggleVerticalMenuActive" />
              <feather-icon :icon="collapseTogglerIconFeather" size="20" class="d-none d-xl-block collapse-toggle-icon" @click="toggleCollapsed" />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0
        }
      "
    >
      <vertical-nav-menu-items :items="navMenuItems" class="navigation navigation-main" />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '../../../../../@core/app-config/useAppConfig'
import { $themeConfig } from '/themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import { APP_CONFIG } from '@/constants/app.js'

export default {
  components: { VuePerfectScrollbar, VerticalNavMenuItems, BLink, BImg },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { isMouseHovered, isVerticalMenuCollapsed, collapseTogglerIcon, toggleCollapsed, updateMouseHovered } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'ChevronsRightIcon' : 'ChevronsLeftIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  computed: {
    appVersion() {
      return APP_CONFIG.version
    },
    navMenuItems() {
      return [
        {
          header: this.$t('visualization'),
        },
        {
          title: this.$t('map'),
          route: 'map',
          icon: 'menu/map-icon',
        },
        {
          header: this.$t('analytics'),
        },
        {
          title: this.$t('timeseries'),
          route: 'timeseries',
          icon: 'menu/timeseries-icon',
        },
        {
          title: this.$t('lines'),
          route: 'lines',
          icon: 'menu/timeseries-icon',
        },
        {
          title: this.$t('scatter'),
          route: 'scatter',
          icon: 'menu/scatter-icon',
        },
        {
          title: this.$t('boxplot'),
          route: 'boxplot',
          icon: 'menu/boxplot-icon',
        },
        {
          title: this.$t('histogram'),
          route: 'histogram',
          icon: 'menu/histogram-icon',
        },
        {
          title: this.$t('decomposition'),
          route: 'decomposition',
          icon: 'menu/decomposition-icon',
        },
        {
          title: this.$t('correlation'),
          route: 'correlation',
          icon: 'menu/correlation-icon',
        },
        {
          title: this.$t('seasonal_analytics'),
          route: 'seasonal-analytics',
          icon: 'menu/seasonal-analytics-icon',
        },
        {
          title: this.$t('scatter_threejs'),
          route: 'scatter-threejs',
          icon: 'menu/scatter-icon',
          description: '(3D)',
        },
        // {
        //   header: 'Beta',
        // },
        {
          header: this.$t('ai'),
        },
        {
          title: this.$t('machine_learning'),
          route: 'machine-learning',
          icon: 'menu/machine-learning-icon',
        },
        {
          header: this.$t('tools'),
        },
        {
          title: this.$t('datasource_tools'),
          route: 'datasource-tools',
          icon: 'tools/tools',
        },
        // {
        //   title: this.$t('aws_s3_manager'),
        //   route: 'aws-s3',
        //   icon: 'static/images/aws-s3/icons/cloud-computing.svg',
        // },
      ]
    },
  },
  methods: {
    openAppByLink(link) {
      open(link)
    },
  },
}
</script>

<style lang="scss">
@import '@/@core/scss/base/core/menu/menu-types/vertical-menu.scss';
</style>

<style scoped>
/* .main-menu .navbar-header .navbar-brand {
  margin-top: calc(1.35rem - 6px);
} */

.brand-text {
  display: flex;
  flex-direction: column;
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navbar-header .brand-text {
  display: flex;
  flex-direction: column;
}

.brand-text .app-name {
  line-height: 22px;
}

.brand-text .app-version {
  font-size: 12px;
  font-weight: 500;
}
</style>
