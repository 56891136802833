export const ACTION_LOG = {
  0: {
    id: 0,
    group: '',
    action: '',
    description: 'Default,Error',
  },
  1: {
    id: 1,
    group: 'Setting',
    action: 'Update setting',
    description: 'Save setting (click), reset setting(click)',
  },
  2: {
    id: 2,
    group: 'Project',
    action: 'New project',
    description: '',
  },
  3: {
    id: 3,
    group: 'Project',
    action: 'Open project',
    description: '',
  },
  4: {
    id: 4,
    group: 'Project',
    action: 'Save project',
    description: 'Save, save as, save local, save cloud',
  },
  5: {
    id: 5,
    group: 'Project',
    action: 'Rename project',
    description: '',
  },
  6: {
    id: 6,
    group: 'Project',
    action: 'Change description project',
    description: '',
  },
  7: {
    id: 7,
    group: 'Datasource',
    action: 'Create datasource',
    description: 'Create datasource from Formula, Frequency, Merge, NA Value, Unit Converter',
  },
  8: {
    id: 8,
    group: 'Datasource',
    action: 'Add datasource',
    description: '',
  },
  9: {
    id: 9,
    group: 'Datasource',
    action: 'Delete datasource',
    description: '',
  },
  10: {
    id: 10,
    group: 'Group',
    action: 'Add group',
    description: '',
  },
  11: {
    id: 11,
    group: 'Group',
    action: 'Update group',
    description: 'Change item in each group',
  },
  12: {
    id: 12,
    group: 'Group',
    action: 'Rename group',
    description: '',
  },
  13: {
    id: 13,
    group: 'Group',
    action: 'Delete group',
    description: '',
  },
  14: {
    id: 14,
    group: 'Group',
    action: 'Hidden/Show group',
    description: '',
  },
  15: {
    id: 15,
    group: 'Layer',
    action: 'Add layer',
    description: '',
  },
  16: {
    id: 16,
    group: 'Layer',
    action: 'Rename layer',
    description: '',
  },
  17: {
    id: 17,
    group: 'Layer',
    action: 'Save layer',
    description: '',
  },
  18: {
    id: 18,
    group: 'Layer',
    action: 'Hidden/Show layer',
    description: '',
  },
  19: {
    id: 19,
    group: 'Layer',
    action: 'Delete layer',
    description: '',
  },
  20: {
    id: 20,
    group: 'Layer',
    action: 'Selected layer',
    description: '',
  },
  21: {
    id: 21,
    group: 'NetCDF',
    action: 'Add file',
    description: 'Time needed to load one file',
  },
  22: {
    id: 22,
    group: 'NetCDF',
    action: 'Create layer netCDF',
    description: 'Time to create one layer',
  },
  23: {
    id: 23,
    group: 'NetCDF',
    action: 'Select cell on Map',
    description: 'Select NetCDF layer then click on the map -> show popup for that cell',
  },
  24: {
    id: 24,
    group: 'NetCDF',
    action: 'Render a frame',
    description: 'Select a date/time, then time until it is ready in time player',
  },
  25: {
    id: 25,
    group: 'NetCDF',
    action: 'Time series select',
    description: 'Select a single cell in Time Serires tab',
  },
  26: {
    id: 26,
    group: 'NetCDF',
    action: 'Initial rendering time',
    description: 'First 30 frames ready for animation',
  },
  27: {
    id: 27,
    group: 'NetCDF',
    action: 'Max FPS',
    description: 'maximum FPS achieved in animation ',
  },
};
