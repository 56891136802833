<template>
  <li class="nav-item">
    <div class="app-help-main">
      <div class="app-help-main-icon" :title="$t('apps')">
        <feather-icon size="21" icon="GridIcon" @click="showHelp = true" />
      </div>
      <div class="app-help-main-modal rounded" :class="{ show: showHelp == true }" ref="appHelpMainModal">
        <div class="help-main">
          <div class="help-main-item text-center" :title="$t('info')" @click="clProjectInfo" :class="project === null || project.source === 'local' ? 'disabled' : ''">
            <div class="help-main-item-icon bg-light-secondary rounded">
              <project-settings :countClick="countClickInfo" />
            </div>
            <div class="help-main-item-text">{{ $t('info') }}</div>
          </div>
          <div class="help-main-item text-center" :title="$t('embed')" @click="clProjectEmbed" :class="project === null || project.source === 'local' ? 'disabled' : ''">
            <div class="help-main-item-icon bg-light-secondary rounded">
              <project-embed :countClick="countClickEmbed" />
            </div>
            <div class="help-main-item-text">{{ $t('embed') }}</div>
          </div>
          <div class="help-main-item text-center" :title="$t('share')" @click="clProjectShare" :class="project === null || project.source === 'local' ? 'disabled' : ''">
            <div class="help-main-item-icon bg-light-secondary rounded">
              <project-share :countClick="countClickShare" />
            </div>
            <div class="help-main-item-text">{{ $t('share') }}</div>
          </div>
          <div class="help-main-item text-center" @click="countClickRecord++" :title="recordStatus === 'recording' ? this.$t('stop_recording') : this.$t('project_recording')">
            <div class="help-main-item-icon bg-light-secondary rounded">
              <project-export :countClick="countClickRecord" @recordingStatus="changeRecordingStatus" />
            </div>
            <div class="help-main-item-text">{{ recordStatus === 'recording' ? $t('stop') : $t('recording') }}</div>
          </div>
          <div class="help-main-item text-center" :title="$t('logs')" @click="countClickLogs++">
            <div class="help-main-item-icon bg-light-secondary rounded">
              <project-log :countClick="countClickLogs" />
            </div>
            <div class="help-main-item-text">{{ $t('logs') }}</div>
          </div>
          <div class="help-main-item text-center" :title="$t('loading')" @click="showModalLoadding">
            <div class="help-main-item-icon bg-light-secondary rounded">
              <b-spinner variant="primary" v-if="isLoadData" style="width: 20px; height: 20px; border: 0.15em solid currentColor; border-right-color: transparent !important; cursor: pointer" />
              <feather-icon icon="AlertOctagonIcon" size="21" v-else />
            </div>
            <div class="help-main-item-text">{{ $t('loading') }}</div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import ProjectSettings from '@/views/ecoplot-desktops/navbar/project-tools/ProjectSettings.vue'
import ProjectEmbed from '@/views/ecoplot-desktops/navbar/project-tools/ProjectEmbed.vue'
import ProjectShare from '@/views/ecoplot-desktops/navbar/project-tools/ProjectShare.vue'
import ProjectExport from '@/views/ecoplot-desktops/navbar/project-tools/ProjectExport.vue'
import ProjectLog from '@/views/ecoplot-desktops/navbar/project-tools/ProjectLog.vue'
export default {
  components: {
    ProjectSettings,
    ProjectEmbed,
    ProjectShare,
    ProjectExport,
    ProjectLog,
  },
  data() {
    return {
      showHelp: false,
      countClickRecord: 0,
      countClickLogs: 0,
      countClickProcess: 0,
      countClickShare: 0,
      countClickEmbed: 0,
      countClickInfo: 0,
      recordStatus: 'stop',
    }
  },
  mounted() {
    const element = this.$refs.appHelpMainModal
    document.addEventListener('mousedown', (event) => {
      if (!element.contains(event.target)) {
        this.showHelp = false
      }
    })
  },
  computed: {
    project() {
      return this.$store.state.ecoplot.project
    },
    modal() {
      return this.$store.state.ecoplot.modal
    },
    isLoadData() {
      try {
        // return this.modal != null && this.modal.close == null && this.modal.percent < 100;
        return this.modal && this.modal.close && this.modal.percent < 100
      } catch {}
      return false
    },
  },
  methods: {
    showModalLoadding() {
      if (this.modal) {
        if (this.modal.global) {
          let globals = this.modal.global
          if (Object.keys(globals).length > 0) {
            let i18nGlobals = {}
            for (let key in globals) {
              i18nGlobals = { ...i18nGlobals, ...{ [key]: this.$t(globals[key]) } }
            }
            this.$store.commit('ecoplot/SET_MODAL', i18nGlobals)
          }
        }
        if (this.modal.content && this.modal.content.length > 0) {
          let contents = this.modal.content
          let i18nContentArray = []
          for (let i = 0; i < contents.length > 0; i++) {
            if (contents[i].global && Object.keys(contents[i].global).length > 0) {
              let globals = contents[i].global
              let i18nGlobals = {}
              for (let key in globals) {
                let globalAddString = globals[key].addString != null ? ' ' + globals[key].addString : ''
                i18nGlobals = { ...i18nGlobals, ...{ [key]: this.$t(globals[key].i18n) + globalAddString } }
              }
              let i18nContent = { ...contents[i], ...i18nGlobals }
              i18nContentArray.push(i18nContent)
            }
          }
          if (i18nContentArray.length > 0) {
            this.$store.commit('ecoplot/SET_MODAL', { content: i18nContentArray })
          }
        }
        this.$store.commit('ecoplot/SET_MODAL', { show: true })
      }
    },
    changeRecordingStatus(status) {
      this.recordStatus = status
    },
    clProjectInfo() {
      try {
        if (this.project === null || this.project.source === 'local') return
        this.countClickInfo++
      } catch {}
    },
    clProjectEmbed() {
      try {
        if (this.project === null || this.project.source === 'local') return
        this.countClickEmbed++
      } catch {}
    },
    clProjectShare() {
      try {
        if (this.project === null || this.project.source === 'local') return
        this.countClickShare++
      } catch {}
    },
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.app-help-main {
  li.nav-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    a.nav-link {
      padding: 0 !important;
    }
  }
}
.vue-cirlce-download-update {
  font-size: 14px !important;
  span {
    margin-bottom: 4px !important;
  }
}
@media (min-width: 576px) {
  .modal-sm {
    max-width: 425px !important;
  }
}
</style>
<style lang="scss" scoped>
.app-help-main {
  position: relative;
  color: #fff;

  .help-main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5rem;
    padding: 0 10px;
    &-item {
      padding: 10px 5px 5px 5px;
      border-radius: 5px;
      cursor: pointer;
      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
        width: 42px;
        height: 38px;
        font-weight: 500;
        position: relative;
        &.run-download {
          background-color: transparent !important;
        }
      }
      &-text {
        margin-top: 5px;
        font-weight: 600;
      }
      &:hover {
        background-color: rgba(57, 123, 255, 0.12);
        color: #43404c;
      }
      &.disabled {
        cursor: not-allowed;
        opacity: 0.6;
        pointer-events: none;
      }
    }
  }
  &-modal {
    position: absolute;
    top: calc(100% + 42px);
    transition: all linear 0.15s;
    right: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    width: 270px;
    background: #fff;
    font-size: 12px;
    padding: 10px;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0 0 0;
    list-style: none;
    background-clip: padding-box;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: #82868b;
    &.show {
      visibility: visible;
      opacity: 1;
      pointer-events: all;
      top: calc(100% + 19px);
    }
    .help-app {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 10px;
      // border-bottom: solid 0.1px rgba(57, 123, 255, 0.12);
      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
    }
  }
  &-icon {
    padding: 0 0.5rem;
    cursor: pointer;
  }
}
.dark-layout .app-help-main {
  &-modal {
    background-color: #283046 !important;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.24);
  }
  .help-main {
    &-item {
      &-icon {
        background: #161d31 !important;
      }
      &:hover {
        color: #dcdada !important;
      }
    }
  }
}
.disabled-li-other-app {
  opacity: 0.6;
  cursor: not-allowed;
}
.disabled-div-other-app {
  opacity: 0.6;
  pointer-events: none;
}
</style>
