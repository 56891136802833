<template>
  <div class="setting-filter-metadata">
    <div class="d-flex justify-content-between">
      <div>
        <b-button class="button-add-filter" variant="primary" draggable="true">
          <feather-icon icon="MousePointerIcon" />
        </b-button>
      </div>

      <div class="text-right">
        <b-button class="mr-1" variant="primary" @click="toggleImportFilterMetadata">{{ $t('import') }}</b-button>
        <b-button variant="primary" @click="exportFilterMetadata">{{ $t('export') }}</b-button>
      </div>
    </div>

    <div class="box-tree-filter">
      <v-jstree ref="tree" v-if="data.length !== 0" :data="data" multiple allow-batch whole-row draggable @item-drop-before="itemDropBefore" @item-drop="itemDrop" drag-over-background-color="#397bff">
        <template slot-scope="_">
          <div class="d-inherit">
            <!-- header -->
            <input v-if="_.model.fixed" :readonly="_.model.fixed" class="input-text-filter header-filter" @input="(e) => editTextFilter(_.model, e.target.value)" :value="$t(_.model.text)" />
            <b-badge v-if="_.model.fixed" class="clearfilter-button" variant="light-primary">{{ countVisible }} / 6 </b-badge>
            <!-- key-value -->
            <input v-if="!_.model.fixed" class="input-text-filter" @input="(e) => editTextFilter(_.model, e.target.value)" :value="$t(_.model.text)" />
            <button v-show="_.model.typeFilter === 'single' && _.model.key" class="action-filter" @click="setTypeFilter(_.model, 'multi')">
              <feather-icon icon="CheckCircleIcon" />
            </button>
            <button v-show="_.model.typeFilter === 'multi' && _.model.key" class="action-filter" @click="setTypeFilter(_.model, 'single')">
              <feather-icon icon="CheckSquareIcon" />
            </button>
            <button v-show="_.model.visible === 'show' && _.model.key" class="action-filter" @click="setVisible(_.model, 'hide')">
              <feather-icon icon="EyeIcon" />
            </button>
            <button v-show="_.model.visible === 'hide' && _.model.key" class="action-filter" @click="setVisible(_.model, 'show')">
              <feather-icon icon="EyeOffIcon" />
            </button>
            <button v-if="!_.model.fixed" class="action-filter" @click="removeItem(_.vm, _.model, $event)">
              <feather-icon icon="XIcon" />
            </button>
          </div>
        </template>
      </v-jstree>
    </div>
    <input type="file" ref="importMetadata" style="display: none" @change="importFilterMetadata" accept=".json" />
  </div>
</template>

<script>
import Papa from 'papaparse'
import Encoding from 'encoding-japanese'
const fs = require('fs')
const _ = require('lodash')
import VJstree from 'vue-jstree'
import DownloadMixin from '@/mixins/DownloadMixin'
import { APP_NAME } from '@/constants/app'
export default {
  components: { VJstree },
  mixins: [DownloadMixin],
  props: ['dataFilterFlag'],
  data() {
    return {
      data: [{ id: 0, text: 'metadata', value: 'Metadata', icon: '', opened: false, selected: true, disabled: false, loading: false, fixed: true, children: [] }],
    }
  },
  mounted() {
    this.data = []
    setTimeout(() => {
      let array = _.cloneDeep(this.dataFilterFlag)
      this.data = array
    }, 0)
  },
  computed: {
    projectName() {
      return this.$store.state.ecoplot.project ? this.$store.state.ecoplot.project.name : APP_NAME
    },
    tempProject() {
      return this.$store.state.ecoplot.tempProject
    },
    countVisible() {
      let count = 0
      if (this.data) {
        let objVisibleShow = this.data[0].children.filter((obj) => obj.visible == 'show')
        count = objVisibleShow.length
      }
      return count
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if (this.data.length !== 0) {
          if (!this.data[0].fixed) this.data[0].fixed = true
          for (let i = 0; i < this.data[0].children.length; i++) {
            if (!this.data[0].children[i].key) this.data[0].children[i].key = true
            if (!this.data[0].children[i].visible) this.data[0].children[i].visible = 'hide'
            if (!this.data[0].children[i].typeFilter) this.data[0].children[i].typeFilter = 'multi'
          }
        }
        // this.$refs.tree.initializeData(this.data);
        this.$emit('getDatafilter', this.data)
      },
    },
    dataFilterFlag() {
      if (this.dataFilterFlag[0].children.length === 0) {
        this.data = []
        setTimeout(() => {
          this.data = [{ id: 0, text: 'metadata', value: 'Metadata', icon: '', opened: false, selected: true, disabled: false, loading: false, fixed: true, children: [] }]
        }, 0)
      }
    },
  },
  methods: {
    toggleImportFilterMetadata() {
      this.$refs.importMetadata.click()
    },
    toggleExportFilterMetadata() {
      this.$refs.exportMetadata.click()
    },
    async importFilterMetadata() {
      const self = this
      const file = this.$refs.importMetadata.files[0]
      try {
        let encoding = 'UTF8'
        try {
          encoding = await this.getEncodingFile(file)
        } catch {}
        Papa.parse(file, {
          encoding,
          skipEmptyLines: 'greedy',
          fastMode: true,
          delimitersToGuess: [],
          complete(result) {
            self.data = []
            try {
              let dataString = ''
              if (result && result.data && result.data.length) {
                for (let i = 0; i < result.data.length; i++) {
                  dataString += result.data[i]
                }
              }
              let metaData = JSON.parse(dataString)
              if (metaData.fixed) {
                self.data = metaData
              } else self.data = [{ id: 0, text: 'metadata', value: 'Metadata', icon: '', opened: false, selected: true, disabled: false, loading: false, fixed: true, children: metaData }]
            } catch {}
          },
        })
      } catch {
        let base = await new Promise((resolve, reject) => {
          let reader = new FileReader()
          reader.onload = async function (e) {
            resolve(reader)
          }
          reader.readAsText(file)
        })
        let dataFile = base.result
        self.data = []
        setTimeout(() => {
          if (JSON.parse(dataFile)[0].fixed) self.data = JSON.parse(dataFile)
          else self.data = [{ id: 0, text: 'Metadata', value: 'Metadata', icon: '', opened: false, selected: true, disabled: false, loading: false, fixed: true, children: JSON.parse(dataFile) }]
        }, 0)
      } finally {
        self.data = []
      }
    },
    exportFilterMetadata() {
      let data = JSON.stringify(this.data)
      this.asJson(`${this.projectName}-Metadata`, data)
    },
    editTextFilter(item, value) {
      item.text = value
      item.value = value
      if (this.data && this.data.length === 1) this.data.push('')
      if (this.data && this.data.length > 1) this.data = [this.data[0]]
    },
    removeItem(node, item, e) {
      var index = node.parentItem.indexOf(item)
      node.parentItem.splice(index, 1)
      if (this.data && this.data.length === 1) this.data.push('')
      if (this.data && this.data.length > 1) this.data = [this.data[0]]
    },
    setTypeFilter(node, value) {
      node.typeFilter = value
      if (this.data && this.data.length === 1) this.data.push('')
      if (this.data && this.data.length > 1) this.data = [this.data[0]]
    },

    setVisible(node, value) {
      if (value === 'show') {
        let objVisibleShow = this.data[0].children.filter((obj) => obj.visible == 'show')
        if (objVisibleShow.length < 6) {
          node.visible = value
        }
      } else {
        node.visible = value
      }
      if (this.data && this.data.length === 1) this.data.push('')
      if (this.data && this.data.length > 1) this.data = [this.data[0]]
    },

    itemDropBefore(node, item, draggedItem, e) {
      if (!draggedItem) {
        item.addChild({
          text: 'new_text',
          value: 'New text',
        })
      }
    },
    itemDrop(node, item) {
      var sortBy = function (attr, rev) {
        if (rev == undefined) {
          rev = 1
        } else {
          rev = rev ? 1 : -1
        }
        return function (a, b) {
          a = a[attr]
          b = b[attr]
          if (a < b) {
            return rev * -1
          }
          if (a > b) {
            return rev * 1
          }
          return 0
        }
      }
      item.children.sort(sortBy('text', true))
    },
    async getEncodingFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = async function (e) {
          try {
            let codes = new Uint8Array(e.target.result)
            let encoding = Encoding.detect(codes) // Detech file encoding
            resolve(encoding)
          } catch {}
          resolve('UTF8')
        }
        reader.readAsArrayBuffer(file)
      })
    },
  },
}
</script>
<style>
.dark-layout .input-text-filter,
.dark-layout .setting-filter-metadata svg {
  color: #fff !important;
  background: transparent !important;
}
.setting-filter-metadata .button-add-filter {
  padding: 10px;
}
.setting-filter-metadata .box-tree-filter {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  max-width: 563px;
}
.setting-filter-metadata .input-text-filter {
  border: 0px !important;
  outline: none;
  background: transparent;
}
.setting-filter-metadata .header-filter {
  width: 280px;
}
.setting-filter-metadata .action-filter {
  border: 0px !important;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}
</style>
