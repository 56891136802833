<template>
  <b-modal :ok-title="$t('save')" ok-variant="success" :cancel-title="$t('cancel')" cancel-variant="flat-secondary" centered :title="$t('config_export')" ref="exportConfig" size="md" @ok="save">
    <b-form-group :label="$t('file_format')">
      <vue-select :reduce="(data) => data.value" :options="fileFormatChoices" v-model="fileFormat" />
    </b-form-group>
  </b-modal>
</template>

<script>
export default {
  computed: {
    fileFormatChoices() {
      return [
        { label: '.mp4', value: 'mp4' },
        { label: '.webm', value: 'webm' },
        // { label: '.gif', value: 'gif' },
      ];
    },
    fileFormat: {
      get() {
        return this.$store.state.ecoplot.fileFormat;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    open() {
      this.$refs.exportConfig.show();
    },
    save() {
      this.$emit('save');
    },
  },
};
</script>
