<template>
  <div v-show="$route && $route.name && $route.name == 'map'">
    <b-button :disabled="isActivate ? false : true" variant="gradient-success" class="map-based-timeseries-loader-wizard" @click.stop="clWizard">{{ $t('load_data') }}</b-button>
  </div>
</template>

<script>
export default {
  props: ['isActivate'],
  data() {
    return {
      mapBaseIsActive: false,
    };
  },
  computed: {},
  watch: {
  },
  methods: {
    clWizard() {
      this.$store.commit('mapBasedTimeseriesLoader/RESET_DATA_MAP_BASED_TIMESERIES_LOADER');
      this.mapBaseIsActive = true;
      this.$store.commit('mapBasedTimeseriesLoader/UPDATE_ACTIVE', this.mapBaseIsActive);
    },
  },
};
</script>

<style lang="scss" scoped></style>
