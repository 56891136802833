<template>
  <li v-if="canViewVerticalNavMenuLink(item)" class="nav-item" :class="{ active: isActive, disabled: item.disabled  || mapBasedTimeseriesLoaderIsActive }">
    <b-link v-bind="linkProps" class="d-flex align-items-center" @click="clickLinkProps(linkProps)">
      <dynamic-icon :icon="item.icon" class="menu-icon" /> 
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <span class="menu-description">{{ item.description }}</span>
      <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '../../../../../../../@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '../../../../../../../@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'
import i18n from '@/libs/i18n'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    // const { canViewVerticalNavMenuLink } = useAclUtils()
    const canViewVerticalNavMenuLink = () => true

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },
  computed: {
    isActivateProduct() {
      return this.$store.state.ecoplot.isActivateProduct
    },
    mapBasedTimeseriesLoaderIsActive() {
      return this.$store.state.mapBasedTimeseriesLoader.isActive
    },
  },
  methods: {
    clickLinkProps(linkProps) {
      if (linkProps.to.name === 'map') {
        setTimeout(() => {
          if (document.querySelector('.leaflet-control-minimap-toggle-display')) {
            let elementMiniMap = document.querySelector('.leaflet-control-minimap-toggle-display')
            if (elementMiniMap.classList.contains('minimized-bottomleft')) {
              elementMiniMap.title = i18n.t('show_miniMap')
            } else {
              elementMiniMap.title = i18n.t('hide_miniMap')
            }
          }
        }, 500)
      }
    },
  },
}
</script>

<style scoped>
.menu-icon {
  width: 22px;
  height: 22px;
  margin-right: 1rem;
  object-fit: contain;
}
.main-menu .navigation > li.active > a > .menu-description {
  color: #fff !important;
}
.menu-description {
  color: #939bb3;
  margin-left: 10px;
  font-size: 13px;
}
</style>
