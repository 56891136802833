import { APP_NAME } from '@/constants/app'
import getBlobDuration from 'get-blob-duration'
import { FFMPEG_EXE_PATH } from '@/constants/paths'
import fs from 'fs'
import html2canvas from 'html2canvas'
import FileSaver from 'file-saver'
const path = require('path')
const { execFileSync } = require('child_process')
import gifshot from 'gifshot'
import GIFEncoder from '@/scripts/videoToGif/GIFEncoder'
export default {
  data() {
    return {
      isRecording: false,
      activeTab: null,
    }
  },
  computed: {
    date() {
      return this.$store.state.map.date
    },
  },
  methods: {
    capture() {
      return new Promise(async (resolve) => {
        let route = this.$router.currentRoute
        if (route.name !== 'map') {
          this.activeTab = route.name
          await this.$router.push('map')
          await new Promise((resolve) => setTimeout(resolve, 500))
        }
        // hide control UI on map
        this.$store.commit('ecoplot/SET_CAPTURE_MODE', true)
        // wait for all control on map to hide
        setTimeout(() => {
          html2canvas(document.getElementsByClassName('map-container')[0], { backgroundColor: null, useCORS: true }).then((canvas) => {
            canvas.toBlob(async (blob) => {
              // show control UI on map
              this.$store.commit('ecoplot/SET_CAPTURE_MODE', false)
              if (this.activeTab) {
                await this.$router.push(this.activeTab)
                await new Promise((resolve) => setTimeout(resolve, 500))
                this.activeTab = null
              }

              resolve(blob)
            })
          })
        }, 100)
      })
    },
    async record() {
      if (!this.isRecording) {
        // start
        this.isRecording = true

        try {
          let recordedChunks = []
          this.mediaStream = await navigator.mediaDevices.getDisplayMedia()
          this.mediaStream.addEventListener('inactive', () => {
            this.isRecording = false
          })
          let mediaRecorder = new MediaRecorder(this.mediaStream, { mimeType: 'video/webm; codecs=vp9' })
          mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              recordedChunks.push(event.data)
              let blob = new Blob(recordedChunks, { type: 'video/webm' })
              FileSaver.saveAs(blob, 'Ecoplot.webm')
            }
          }
          mediaRecorder.start()
        } catch (err) {
          console.error(err)
        }
      } else {
        // stop
        this.isRecording = false

        this.mediaStream.getTracks().forEach((track) => track.stop())
      }
    },
    async recordFormat(type) {
      if (!this.isRecording && type) {
        // start
        this.isRecording = true
        try {
          let recordedChunks = []
          let screenWidth
          let screenHeight
          this.mediaStream = await navigator.mediaDevices.getDisplayMedia()
          if (type == 'gif') {
            try {
              const videoTrack = this.mediaStream.getVideoTracks()[0]
              const settings = videoTrack.getSettings()
              screenWidth = settings.width
              screenHeight = settings.height
            } catch {
              screenWidth = window.innerWidth
              screenHeight = window.innerHeight
            }
          }
          this.mediaStream.addEventListener('inactive', () => {
            this.isRecording = false
          })
          let mediaRecorder = new MediaRecorder(this.mediaStream, { mimeType: `video/webm; codecs=vp9` })
          mediaRecorder.ondataavailable = async (event) => {
            if (event.data.size > 0) {
              recordedChunks.push(event.data)
              let blob
              if (type == 'gif') {
                blob = new Blob(recordedChunks, { type: `video/webm` })
              } else {
                blob = new Blob(recordedChunks, { type: `video/${type}` })
              }
              if (type == 'mp4' || type == 'webm') {
                FileSaver.saveAs(blob, `Ecoplot.${type}`)
              } else if (type == 'gif') {
                // -----------------C1 : Use library gifshot.js------------------------
                const videoURL = URL.createObjectURL(blob)
                const duration = await getBlobDuration(videoURL)
                function progressCallbackFunc(captureProgress) {
                  console.log(`captureProgress=${(captureProgress * 100).toFixed(2)}%`)
                }
                let FPS = 6
                if (duration < 20) {
                  FPS = 6
                } else if (duration < 40) {
                  FPS = 5
                } else if (duration < 60) {
                  FPS = 4
                } else {
                  FPS = 3
                }
                let interval = 1 / FPS
                let numFrames = Math.floor(duration * FPS)
                let configGif = {
                  video: [videoURL],
                  progressCallback: progressCallbackFunc,
                  interval: interval, // Tốc độ chuyển khung
                  numFrames: numFrames, // Số lượng khung hình (tuỳ chỉnh)
                  sampleInterval: 12,
                }
                if (screenWidth && screenHeight) {
                  configGif.gifWidth = screenWidth
                  configGif.gifHeight = screenHeight
                }
                gifshot.createGIF(configGif, function (obj) {
                  if (!obj.error) {
                    const gifImg = obj.image
                    FileSaver.saveAs(gifImg, `Ecoplot.${type}`)
                    console.log("Done !")
                  } else {
                    this.isRecording = false
                    console.error('Error in GIF creation', obj.error)
                  }
                })

                // // -----------------C2 : Use library gif-encoder.js------------------------
                // const base64Str = await new Promise((resolve, reject) => {
                //   var reader = new FileReader()
                //   reader.readAsDataURL(blob)
                //   reader.onloadend = function () {
                //     resolve(reader.result)
                //   }
                // })
                // const imageGifBuf = await this.videoToGif(base64Str)

                // let dwnlnk = document.createElement('a')
                // dwnlnk.download = `Ecoplot.gif`
                // dwnlnk.innerHTML = `<small>Save</small>`
                // dwnlnk.className = 'btn btn-outline-dark'
                // dwnlnk.href = imageGifBuf
                // dwnlnk.click()
                // window.URL.revokeObjectURL(dwnlnk)
              }
            }
          }
          mediaRecorder.start()
        } catch (err) {
          //iserclick cancel
          this.isRecording = false
        }
      } else {
        // stop
        this.isRecording = false
        this.mediaStream.getTracks().forEach((track) => track.stop())
      }
    },
    gifing() {
      return new Promise((resolve, reject) => {
        if (!this.date.length) {
          reject('No dates to loop')
          return
        }

        // turn on overlay
        this.$store.commit('ecoplot/SET_OVERLAY', true)

        // hide control UI on map
        this.$store.commit('ecoplot/SET_CAPTURE_MODE', true)

        // calculate rect
        let domRect = document.getElementsByClassName('map-list')[0].getBoundingClientRect()
        let { x, y, w, h } = { x: Math.ceil(domRect.left), y: Math.ceil(domRect.top), w: Math.floor(domRect.width), h: Math.floor(domRect.height) }

        // wait for all control on map to hide
        setTimeout(() => {
          this.record()
            .then((webm) => {
              let inputPath = path.join(FFMPEG_EXE_PATH, 'input.webm')
              fs.writeFileSync(inputPath, webm)

              execFileSync('ffmpeg.exe', ['-i', 'input.webm', '-vf', `crop=${w}:${h}:${x}:${y}`, '-y', 'output.gif'], { cwd: FFMPEG_EXE_PATH })

              let outputPath = path.join(FFMPEG_EXE_PATH, 'output.gif')
              let gif = fs.readFileSync(outputPath)

              resolve(gif)
            })
            .catch((err) => {
              reject(err)
            })
            .finally(() => {
              // show control UI on map
              this.$store.commit('ecoplot/SET_CAPTURE_MODE', false)

              // turn off overlay
              this.$store.commit('ecoplot/SET_OVERLAY', false)
            })
        }, 100)
      })
    },
    cancel() {
      this.cancelWalk(() => {
        this.isCancel = true
        this.stream.getTracks().forEach((track) => track.stop())
      })
    },
    async videoToGif(b64Str) {
      return new Promise(async (resolve, reject) => {
        console.log('Convert video to gif.....')
        const self = this
        const displayedSize = 1920

        const scale = window.devicePixelRatio
        function encode64(input) {
          var output = '',
            i = 0,
            l = input.length,
            key = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
            chr1,
            chr2,
            chr3,
            enc1,
            enc2,
            enc3,
            enc4
          while (i < l) {
            chr1 = input.charCodeAt(i++)
            chr2 = input.charCodeAt(i++)
            chr3 = input.charCodeAt(i++)
            enc1 = chr1 >> 2
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
            enc4 = chr3 & 63
            if (isNaN(chr2)) enc3 = enc4 = 64
            else if (isNaN(chr3)) enc4 = 64
            output = output + key.charAt(enc1) + key.charAt(enc2) + key.charAt(enc3) + key.charAt(enc4)
          }
          return output
        }
        var continueCallback = true
        var FPS = 0
        function toggleImageSmoothing(_CANVAS, isEnabled) {
          _CANVAS.getContext('2d').mozImageSmoothingEnabled = isEnabled
          _CANVAS.getContext('2d').webkitImageSmoothingEnabled = isEnabled
          _CANVAS.getContext('2d').msImageSmoothingEnabled = isEnabled
          _CANVAS.getContext('2d').imageSmoothingEnabled = isEnabled
        }

        function scaleCanvas(_CANVAS, videoObj, vidHeight, vidWidth, scale) {
          _CANVAS['style']['height'] = `${vidHeight}px`
          _CANVAS['style']['width'] = `${vidWidth}px`

          let cWidth = vidWidth * scale
          let cHeight = vidHeight * scale

          _CANVAS.width = cWidth
          _CANVAS.height = cHeight

          toggleImageSmoothing(_CANVAS, true)
          _CANVAS.getContext('2d').scale(scale, scale)
        }

        const loadVideo = (url) =>
          new Promise((resolve, reject) => {
            var vid = document.createElement('video')
            vid.addEventListener('canplay', () => resolve(vid))
            vid.addEventListener('error', (err) => reject(err))
            vid.src = url
          })
        if (!window.FileReader) {
          resolve()
          return
        }
        if (!b64Str) {
          resolve()
          return
        }
        let videoObj = await loadVideo(b64Str)
        videoObj.autoplay = false
        videoObj.muted = true
        videoObj.loop = false

        let exactVideoDuration = videoObj.duration
        let vidHeight = videoObj.videoHeight // 720
        let vidWidth = videoObj.videoWidth // 1280
        videoObj.height = vidHeight
        videoObj.width = vidWidth
        videoObj['style']['height'] = `${vidHeight}px`
        videoObj['style']['width'] = `${vidWidth}px`

        let _CANVAS = document.createElement('canvas')
        scaleCanvas(_CANVAS, videoObj, vidHeight, vidWidth, scale)
        // =============== calculate displayed sizes ====================
        let totalFrames = 33
        if (exactVideoDuration <= 10) {
          totalFrames = 33
        } else if (exactVideoDuration <= 12) {
          totalFrames = 25
        } else if (exactVideoDuration <= 15) {
          totalFrames = 20
        } else if (exactVideoDuration <= 25) {
          totalFrames = 12
        } else if (exactVideoDuration <= 30) {
          totalFrames = 10
        } else if (exactVideoDuration <= 35) {
          totalFrames = 8
        } else if (exactVideoDuration <= 42) {
          totalFrames = 7
        } else if (exactVideoDuration <= 60) {
          totalFrames = 5
        }
        let sizeBenchmark = vidHeight
        if (vidWidth > vidHeight) {
          sizeBenchmark = vidWidth
        }
        let scaleRatio = parseFloat(displayedSize / sizeBenchmark)
        let displayedHeight = scaleRatio * vidHeight
        let displayedWidth = scaleRatio * vidWidth
        // console.log(`vidWidth=${vidWidth} vidHeight=${vidHeight}`)
        displayedWidth = vidWidth
        displayedHeight = vidHeight

        videoObj['style']['height'] = `${displayedHeight}px`
        videoObj['style']['width'] = `${displayedWidth}px`

        scaleCanvas(_CANVAS, videoObj, displayedHeight, displayedWidth, scale)

        let encoder = new GIFEncoder(vidWidth, vidHeight)
        encoder.setRepeat(0) // 0 for repeat, -1 for no-repeat
        encoder.setDelay(0) // frame delay in ms // 500
        encoder.setQuality(16) // [1,30] | Best=1 | >20 not much speed improvement. 10 is default.

        // Sets frame rate in frames per second
        var startTime = 0
        var frameIndex = 0
        const step = async () => {
          startTime = startTime == 0 ? Date.now() : 0
          _CANVAS.getContext('2d').drawImage(videoObj, 0, 0, displayedWidth, displayedHeight)
          encoder.addFrame(_CANVAS.getContext('2d'))
          frameIndex++
          console.log(`Step ${frameIndex}`)
          if (FPS == 0) {
            let ms_elapsed = Date.now() - startTime
            FPS = (frameIndex / ms_elapsed) * 1000.0
            let encodeDelaySetting = FPS * exactVideoDuration >= totalFrames ? 0 : (totalFrames * 1.0) / exactVideoDuration - FPS
            encodeDelaySetting = Math.floor(encodeDelaySetting * 1000)
            encoder.setDelay(encodeDelaySetting)
          }

          if (continueCallback) {
            videoObj.requestVideoFrameCallback(step)
          }
        }

        videoObj.addEventListener(
          'play',
          (vEvt) => {
            if (continueCallback) {
              videoObj.requestVideoFrameCallback(step)
            }
            encoder.start()
          },
          false
        )

        videoObj.addEventListener(
          'ended',
          (vEvt) => {
            continueCallback = false
            encoder.finish()
            var fileType = 'image/gif'
            // var fileName = `gif-output-${new Date().toGMTString().replace(/(\s|,|:)/g, '')}.gif`
            var readableStream = encoder.stream()
            var binary_gif = readableStream.getData()
            var b64Str = 'data:' + fileType + ';base64,' + encode64(binary_gif)
            console.log(`Done!`)
            resolve(b64Str)
            return b64Str
          },
          false
        )

        videoObj.play()
      })
    },
  },
}
