<template>
  <b-nav-item :title="$t('embed')" @click="openModal" class="project-embed" :class="project === null || project.source === 'local' ? 'disabled not-allowed' : ''">
    <feather-icon size="21" icon="CodeIcon" :style="{ color: '#82868b !important' }" />
    <b-modal centered :title="$t('embed_project')" ref="embedModal" :hide-footer="true">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group :label="$t('width')" label-for="embed-width">
              <b-form-input id="embed-width" :placeholder="$t('width')" v-model="width" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('height')" label-for="embed-height">
              <b-form-input id="embed-height" :placeholder="$t('height')" v-model="height" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group :label="$t('startat')">
              <b-form-checkbox v-model="startAt" switch />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group class="mb-0">
              <b-form-input id="embed-link" :value="valueInput" readonly />
              <!-- <b-form-input :value="`<iframe src=&quot;${url}/#/embed/?projectId=${project ? project.id : ''}&quot; width=&quot;${width}&quot; height=&quot;${height}&quot; style=&quot;border: none;&quot;></iframe>`" id="embed-link" readonly /> -->
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-button variant="primary" @click="doCopy">{{ $t('copy') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-nav-item>
</template>

<script>
import { BNavItem, BTooltip, BModal, BForm, BRow, BCol, BFormGroup, BFormCheckbox, BFormInput, BButton } from 'bootstrap-vue'
import { ECOPLOT_PRO } from '@/constants/urls.js'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  props: ['countClick'],
  components: { BNavItem, BTooltip, BModal, BForm, BRow, BCol, BFormGroup, BFormCheckbox, BFormInput, BButton },
  data() {
    return {
      url: ECOPLOT_PRO,
      width: 900,
      height: 600,
      startAt: false,
      valueInput: `<iframe src="${this.url}/#/embed/?projectId=${this.project ? this.project.id : ''}" width="${this.width}" height="${this.height}" style="border: none;"></iframe>`,
    }
  },
  computed: {
    project() {
      return this.$store.state.ecoplot.project
    },
    date() {
      return this.$store.state.map.date
    },
    message() {
      return `<iframe src="${this.url}/#/embed/?projectId=${this.project ? this.project.id : ''}" width="${this.width}" height="${this.height}" style="border: none;"></iframe>`
    },
    watchUrl() {
      return `${this.startAt}_${this.width}_${this.height}_${this.date}`
    },
  },
  watch: {
    countClick() {
      this.$refs.embedModal.show()
    },
    watchUrl() {
      if (this.startAt) {
        let dateStartAt = ''
        if (this.date) {
          dateStartAt = moment(this.date[0]).format('X')
        }
        this.valueInput = `<iframe src="${this.url}/#/embed/?projectId=${this.project ? this.project.id : ''}&date=${dateStartAt}" width="${this.width}" height="${this.height}" style="border: none;"></iframe>`
      } else {
        this.valueInput = `<iframe src="${this.url}/#/embed/?projectId=${this.project ? this.project.id : ''}" width="${this.width}" height="${this.height}" style="border: none;"></iframe>`
      }
    },
  },
  methods: {
    openModal() {
      this.$refs.embedModal.show()
    },
    doCopy() {
      let copyText = document.getElementById('embed-link')
      copyText.select()
      navigator.clipboard.writeText(copyText.value)

      this.$toast({ component: ToastificationContent, props: { title: 'Text copied', icon: 'BellIcon' } })
    },
  },
}
</script>

<style scoped>
.project-embed.disabled {
  cursor: not-allowed;
}
.project-embed.disabled * {
  pointer-events: none;
}
</style>
